/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import { palette } from "src/theme";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MaskStyle } from "src/styles/globalStyle";
import {
  ButtonClosedForm,
  ButtonDefault,
  ButtonDelete,
  ButtonInclude,
  ButtonSalveForm,
} from "src/components/ButtonsCommons";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import useLayout from "src/hooks/useLayout";
import ItemRequest from "../ItemRequest";
import useRequest from "src/hooks/useRequest";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { useEffect } from "react";
import DialogComponent from "src/components/Dialog";
import useRequestStatus from "src/hooks/useRequestStatus";
import CloseIcon from "@mui/icons-material/Close";
import useRequestItem from "src/hooks/useRequestItem";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import { addHours, format } from "date-fns";
import { iCompany } from "src/interfaces/company";
import useCompany from "src/hooks/useCompany";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const numberSumWithPage = 40;

const header = [
  {
    label: "Código",
  },
  {
    label: "Descrição",
  },
  {
    label: "Unidade",
  },
  {
    label: "Quantidade",
  },
  {
    label: "Valor unitário",
  },
  {
    label: "Valor Total",
  },
];

const CreateOrUpdate: React.FC = () => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const router = useNavigate();

  const {
    setOpenDialog,
    openDialogSecondary,
    setOpenDialogSecondary,
    disableButtons,
    openDialogThree,
    setOpenDialogThree,
    openDialogFour,
    setOpenDialogFour,
    width,
  } = useLayout();

  const {
    requestSelect,
    setShoppingProccessId,
    number,
    setNumber,
    setBuyerId,
    setBuyer,
    setSupplierId,
    supplierSelect,
    setSupplierSelect,
    setSupplier,
    emissionDate,
    setEmissionDate,
    deliveryDate,
    setDeliveryDate,
    paymentPrevisionDate,
    setPaymentPrevisionDate,
    value,
    getLastShoppingProccessPhase,
    buyers,
    suppliers,
    setShoppingProccessDescription,
    itemsPhaseShoppingProccess,
    itemsToSearch,
    setItemsToSearch,
    status,
    setStatus,
    handleSelected,
    handleUpdateRequest,
    handleSalveNewRequest,
    paymentCondition,
    setPaymentCondition,
    loading: loadingRequest,
    observationStatusReqeuest,
    setObservationStatusRequest,
    requestSelectSearch,
    setRequestSelectSearch,
    buyerSelect,
    setBuyerSelect,
    setPaymentDate,
  } = useRequest();

  const {
    loading: loadingRequestItem,
    getRequestItem,
    requestItem,
    setRequestItems,
    handleSelect,
    requestItemSelect,
    handleSelectedChecked,
    page: pageRequestItem,
    handleChangePage: handleChangePageRequestItem,
    handleChangeRowsPerPage: handleChangeRowsPerPageRequestItem,
    handleDelete,
  } = useRequestItem();

  const {
    getRequestStatus,
    requestStatus,
    loading: loadingRequestStatus,
  } = useRequestStatus();

  const { getPaymentConditions, paymentConditions } = usePaymentCondition();

  const {
    loading,
    setPage,
    getShoppingProcess,
    shoppingProcessToSearch,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useShoppingProcess();

  const {
    getCompanyBuyer,
    loading: loadingCompany,
    companysToSearchBuyer,
    getCompanySupplier,
    companysToSearchSupplier,
    nameSupplySearch,
    setNameSupplySearch,
    nameSearch,
    setNameSearch,
    page: pageCompany,
    setPage: setPageCompany,
    handleChangePage: handleChangePageCompany,
    handleChangeRowsPerPage: handleChangeRowsPerPageCompany,
  } = useCompany();

  useEffect(() => {
    getRequestStatus();
    handleSelectedChecked(null);
    setPage({
      ...page,
      page: 0,
      rowsPerPage: 10,
      total: 0,
      change: false,
    });

    return () => {
      setNameSearch("");
      setNameSupplySearch("");
    };
  }, []);

  useEffect(() => {
    if (requestSelect?.id) {
      getRequestItem(requestSelect.id);
    } else {
      setRequestItems([]);
    }
  }, [pageRequestItem]);

  useEffect(() => {
    if (nameSearch.length >= 4) {
      getCompanyBuyer(numberSumWithPage);
    } else if (nameSearch.length === 0) {
      getCompanyBuyer(numberSumWithPage);
    }
  }, [nameSearch, pageCompany.page]);

  useEffect(() => {
    if (nameSupplySearch.length >= 4) {
      getCompanySupplier(numberSumWithPage);
    } else if (nameSupplySearch.length === 0) {
      getCompanySupplier(numberSumWithPage);
    }
  }, [nameSupplySearch]);

  useEffect(() => {
    getShoppingProcess();
  }, [page.page]);

  useEffect(() => {
    setPaymentDate({
      id: paymentCondition,
      paymentConditions: paymentConditions,
    });
  }, [paymentConditions]);

  const onChangeBuyer = async (_: any, value: any) => {
    if (value?.id) {
      let newArray = itemsPhaseShoppingProccess?.filter(
        (item: any) => item.compradorId === value.id
      );

      if (itemsToSearch.length === 0) {
        setItemsToSearch(newArray);
      } else {
        const newhOtherArray = newArray.filter(
          (item) => !itemsToSearch.includes(item)
        );

        setItemsToSearch([...newArray, ...newhOtherArray]);
      }

      setBuyerSelect(value);
      setBuyerId(value.id);
      setBuyer(value.label);
      setPaymentCondition(value.condicaoPagamentoId ?? "");

      await getPaymentConditions({
        id: value.id,
      } as iCompany);
    } else {
      setBuyerSelect(null);
      setBuyerId("");
      setBuyer("");
      setPaymentCondition("");
    }
  };

  const onChangeSupplier = async (_: any, value: any) => {
    if (value?.id) {
      let newArray = itemsPhaseShoppingProccess?.filter(
        (item: any) => item.fornecedorId === value.id
      );

      if (itemsToSearch.length === 0) {
        setItemsToSearch(newArray);
      } else {
        const newhOtherArray = newArray.filter(
          (item) => !itemsToSearch.includes(item)
        );

        setItemsToSearch([...newArray, ...newhOtherArray]);
      }

      setSupplierSelect(value);
      setSupplierId(value.id);
      setSupplier(value.label);
    } else {
      setSupplierSelect(null);
      setSupplierId("");
      setSupplier("");
    }
  };

  return (
    <>
      {loading || loadingRequestStatus ? (
        <CircularLoading />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if (requestSelect?.id) {
              handleUpdateRequest();
            } else {
              handleSalveNewRequest();
            }
          }}
        >
          <Card sx={{ padding: 2, width: "100%" }}>
            <Typography gutterBottom variant="h6" component="div">
              Dados Gerais
            </Typography>
            <Divider
              style={{
                backgroundColor: palette.primary.main,
                marginBottom: 15,
              }}
            />
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid xs={12} sm={12} md={12}>
                <ItemCustom>
                  {width >= 1400 ? (
                    <FormLabel sx={{ width: "19%" }}>Processo</FormLabel>
                  ) : null}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="show_select_code"
                    options={shoppingProcessToSearch}
                    value={requestSelectSearch}
                    getOptionLabel={(option) =>
                      `${option?.code} - ${option.label}`
                    }
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setRequestSelectSearch(value);
                        setShoppingProccessId(value.id);
                        setShoppingProccessDescription(value.label);
                        getLastShoppingProccessPhase(value.id);
                      } else {
                        setRequestSelectSearch(null);
                        setShoppingProccessId("");
                        setShoppingProccessDescription("");
                        setBuyerId("");
                        setBuyer("");
                        setSupplierId("");
                        setSupplier("");
                        setPaymentCondition("");
                        setPaymentPrevisionDate("");
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Selecione:" size="small" />
                    )}
                  />
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => setOpenDialogSecondary(true)}
                  >
                    <SearchIcon />
                  </IconButton>
                </ItemCustom>
              </Grid>
              {loadingRequest ? (
                <Grid
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  sx={{ marginTop: 5 }}
                >
                  <CircularLoading />
                </Grid>
              ) : (
                <>
                  <Grid xs={12} sm={12} md={12}>
                    <ItemCustom>
                      {width >= 1400 ? (
                        <FormLabel sx={{ width: "18%" }}>
                          Número do pedido:{" "}
                        </FormLabel>
                      ) : null}
                      <TextField
                        fullWidth
                        size="small"
                        id="description"
                        name="description"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        label="Número do pedido"
                        required
                        variant="outlined"
                      />
                    </ItemCustom>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <ItemCustom>
                      {width >= 1400 ? (
                        <FormLabel sx={{ width: "18%" }}>Comprador</FormLabel>
                      ) : null}
                      <Autocomplete
                        fullWidth
                        disablePortal
                        sx={{ width: "100%" }}
                        id="show_select_administrator_company"
                        options={
                          buyers.length !== 0 ? buyers : companysToSearchBuyer
                        }
                        value={buyerSelect}
                        getOptionLabel={(option) => option.label ?? ""}
                        onChange={onChangeBuyer}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione:"
                            size="small"
                            required
                            onChange={(e) => setNameSearch(e.target.value)}
                          />
                        )}
                      />
                      <IconButton
                        aria-label="search"
                        color="primary"
                        onClick={() => setOpenDialogThree(true)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </ItemCustom>
                  </Grid>
                  <Grid xs={12} sm={12} md={12}>
                    <ItemCustom>
                      {width >= 1400 ? (
                        <FormLabel sx={{ width: "18%" }}>Fornecedor</FormLabel>
                      ) : null}
                      <Autocomplete
                        fullWidth
                        disablePortal
                        id="show_select_administrator_responsible"
                        options={
                          suppliers.length !== 0
                            ? suppliers
                            : companysToSearchSupplier
                        }
                        value={supplierSelect}
                        sx={{ width: "100%" }}
                        getOptionLabel={(option) => option.label ?? ""}
                        onChange={onChangeSupplier}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione:"
                            size="small"
                            required
                            onChange={(e) =>
                              setNameSupplySearch(e.target.value)
                            }
                          />
                        )}
                      />
                      <IconButton
                        aria-label="search"
                        color="primary"
                        onClick={() => setOpenDialogFour(true)}
                      >
                        <SearchIcon />
                      </IconButton>
                    </ItemCustom>
                  </Grid>

                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "47%" }}>
                          Data emissão pedido:{" "}
                        </FormLabel>
                      ) : null}
                      <FormControl fullWidth>
                        <MaskStyle>
                          <input
                            type="date"
                            name="end_date"
                            placeholder="dd/mm/yyyy"
                            min="1970-01-01"
                            required
                            value={emissionDate}
                            onChange={(e) => setEmissionDate(e.target.value)}
                          />
                        </MaskStyle>
                      </FormControl>
                    </ItemCustomStart>
                  </Grid>
                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "30%" }}>
                          Previsão pgto:{" "}
                        </FormLabel>
                      ) : null}
                      <FormControl fullWidth>
                        <MaskStyle>
                          <input
                            type="date"
                            name="end_date"
                            placeholder="dd/mm/yyyy"
                            min="1970-01-01"
                            value={paymentPrevisionDate}
                            onChange={(e) =>
                              setPaymentPrevisionDate(e.target.value)
                            }
                          />
                        </MaskStyle>
                      </FormControl>
                    </ItemCustomStart>
                  </Grid>
                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "35%" }}>
                          Valor pedido:{" "}
                        </FormLabel>
                      ) : null}
                      <TextField
                        size="small"
                        fullWidth
                        id="value"
                        name="value"
                        label="R$ ##.###,00"
                        required
                        variant="outlined"
                        disabled
                        value={value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      />
                    </ItemCustomStart>
                  </Grid>
                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "47%" }}>
                          Data previsão entrega:{" "}
                        </FormLabel>
                      ) : null}
                      <FormControl fullWidth>
                        <MaskStyle>
                          <input
                            type="date"
                            name="end_date"
                            placeholder="dd/mm/yyyy"
                            min="1970-01-01"
                            value={deliveryDate}
                            onChange={(e) => setDeliveryDate(e.target.value)}
                          />
                        </MaskStyle>
                      </FormControl>
                    </ItemCustomStart>
                  </Grid>
                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "30%" }}>
                          {translations[param]["status"]}:{" "}
                        </FormLabel>
                      ) : null}
                      <Select
                        fullWidth
                        labelId="show_select_type"
                        id="show_select_status"
                        value={status}
                        size="small"
                        onChange={(event: SelectChangeEvent) => {
                          setStatus(event?.target?.value as string);
                        }}
                      >
                        <MenuItem value="select" disabled>
                          {width <= 1400
                            ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                            : translations[param]["select"]}
                        </MenuItem>
                        <MenuItem value="">...</MenuItem>
                        {requestStatus?.map((item) => (
                          <MenuItem key={item?.id} value={item!.id?.toString()}>
                            {item?.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </ItemCustomStart>
                  </Grid>
                  <Grid xs={4} sm={4} md={4}>
                    <ItemCustomStart>
                      {width >= 1400 ? (
                        <FormLabel sx={{ minWidth: "35%" }}>
                          {translations[param]["payment_condition"]}
                        </FormLabel>
                      ) : null}
                      <Select
                        fullWidth
                        labelId="show_select_type"
                        id="show_select_status"
                        value={paymentCondition}
                        size="small"
                        onChange={(event: SelectChangeEvent) => {
                          setPaymentDate({
                            id: `${event.target.value}`,
                            paymentConditions: paymentConditions,
                          });
                        }}
                      >
                        <MenuItem value="select" disabled>
                          {width <= 1400
                            ? `${translations[param]["payment_condition"]} - ${translations[param]["select"]}`
                            : translations[param]["select"]}
                        </MenuItem>
                        <MenuItem value="">...</MenuItem>
                        {paymentConditions?.map((item) => (
                          <MenuItem key={item.id} value={`${item.id}`}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </ItemCustomStart>
                  </Grid>
                  {requestSelect?.id &&
                  status !== "select" &&
                  requestSelect?.statusCurrent?.pedidoStatusId !== status ? (
                    <Grid xs={4} sx={{ marginTop: 2 }}>
                      <TextField
                        fullWidth
                        id="observations"
                        label={`Observação sobre a alteração do status!`}
                        multiline
                        maxRows={4}
                        value={observationStatusReqeuest}
                        onChange={(e) =>
                          setObservationStatusRequest(e.target.value)
                        }
                      />
                    </Grid>
                  ) : null}
                </>
              )}
            </Grid>
          </Card>

          <Card sx={{ padding: 2, marginTop: 3, width: "100%" }}>
            <FormLabel>Itens</FormLabel>
            <Divider
              sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
            />
            <Grid sx={{ marginBottom: 2 }} display="flex">
              {requestSelect?.id ? (
                <ButtonInclude
                  label="Incluir"
                  disabled={!disableButtons}
                  onClick={() => setOpenDialog(true)}
                />
              ) : (
                <ButtonSalveForm
                  label="Incluir"
                  colorIcon="success"
                  type="submit"
                  disabled={!disableButtons}
                />
              )}

              <ButtonDelete
                label="Excluir"
                disabled={disableButtons}
                onClick={() =>
                  Swal.fire({
                    title: "Atenção!",
                    text: "O item do processo de compra será deletado, deseja continuar ? ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: palette.primary.main,
                    cancelButtonColor: palette.error.main,
                    confirmButtonText: "Sim, deletar!",
                    cancelButtonText: "Não",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDelete(requestItemSelect, requestSelect?.id ?? "");
                    }
                  })
                }
              />
            </Grid>
            {loadingRequestItem ? (
              <CircularLoading />
            ) : requestItem?.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={pageRequestItem}
                handleChangePage={handleChangePageRequestItem}
                handleChangeRowsPerPage={handleChangeRowsPerPageRequestItem}
              >
                {requestItem?.map((item) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item?.produtoEmpresa?.codigoProdutoEmpresa}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item?.produtoEmpresa?.descricao}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item?.produtoEmpresa?.unidade}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {item?.quantidade?.toLocaleString("pt-br")}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {parseFloat(item?.valorUnitario)?.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleSelect(item);
                        setOpenDialog(true);
                      }}
                    >
                      {parseFloat(item?.valorTotal)?.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={item.id === requestItemSelect?.id}
                        size="small"
                        onClick={() => handleSelectedChecked(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Card>

          <Card sx={{ marginTop: "2%" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Andamento do pedido</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {requestSelect?.statusRequest?.length === 0 ? (
                  <Empty />
                ) : (
                  <TableComponent
                    header={[
                      {
                        label: "Status",
                      },
                      {
                        label: "Data",
                      },
                      {
                        label: "Usuário Responsável",
                      },
                      {
                        label: "Observação",
                      },
                    ]}
                    page={{
                      page: 0,
                      rowsPerPage: 5000,
                      total: requestSelect?.statusRequest?.length,
                    }}
                    handleChangePage={() => {}}
                    handleChangeRowsPerPage={() => {}}
                  >
                    {requestSelect?.statusRequest?.map((item: any) => (
                      <TableRow
                        hover
                        key={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item?.pedidoStatus?.descricao}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.data &&
                            format(
                              addHours(new Date(item.data), 3),
                              "dd/MM/yyyy hh:MM:ss"
                            )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.usuarioSistemaResponsavel}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {item?.observacao}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableComponent>
                )}
              </AccordionDetails>
            </Accordion>
          </Card>

          <Grid
            display="flex"
            justifyContent="end"
            alignItems="center"
            sx={{ marginTop: 5 }}
          >
            <ButtonClosedForm
              label="Fechar"
              onClick={() => {
                handleSelected(null);
                router(-1);
              }}
            />
            {requestSelect?.id ? (
              <ButtonSalveForm
                label="Alterar"
                colorIcon="success"
                type="submit"
              />
            ) : (
              <ButtonSalveForm
                label="Salvar"
                colorIcon="success"
                type="submit"
              />
            )}
          </Grid>
        </form>
      )}

      <ItemRequest />

      <DialogComponent open={openDialogSecondary} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Processos</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenDialogSecondary(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loading ? (
            <CircularLoading />
          ) : shoppingProcessToSearch?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "Número",
                },
                {
                  label: "Descrição",
                },
                {
                  label: "Taxa",
                },
                {
                  label: "Vigência",
                },
                {
                  label: "Comprador",
                },
              ]}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {shoppingProcessToSearch.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShoppingProccessId(item.id);
                    getLastShoppingProccessPhase(item.id);
                    setShoppingProccessDescription(item.label);
                    setOpenDialogSecondary(false);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.code}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.rateValue}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.numberMonth}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.buyer}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => setOpenDialogSecondary(false)}
          />
        </DialogActions>
      </DialogComponent>

      <DialogComponent open={openDialogThree} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Compradores</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogThree(false);
            setPageCompany({
              ...pageCompany,
              page: 0,
            });
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingCompany ? (
            <CircularLoading />
          ) : companysToSearchBuyer?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "CNPJ",
                },
                {
                  label: "Comprador",
                },
              ]}
              page={pageCompany}
              handleChangePage={handleChangePageCompany}
              handleChangeRowsPerPage={handleChangeRowsPerPageCompany}
            >
              {companysToSearchBuyer.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChangeBuyer(undefined, item);
                    setOpenDialogThree(false);
                    setPageCompany({
                      ...pageCompany,
                      page: 0,
                    });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.cnpj}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialogThree(false);
              setPageCompany({
                ...pageCompany,
                page: 0,
              });
            }}
          />
        </DialogActions>
      </DialogComponent>

      <DialogComponent open={openDialogFour} handleClose={() => {}}>
        <DialogTitle id="alert-dialog-title">Fornecedores</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenDialogFour(false);
            setPageCompany({
              ...pageCompany,
              page: 0,
            });
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {loadingCompany ? (
            <CircularLoading />
          ) : companysToSearchSupplier?.length === 0 ? (
            <Empty />
          ) : (
            <TableComponent
              header={[
                {
                  label: "CNPJ",
                },
                {
                  label: "Comprador",
                },
              ]}
              page={pageCompany}
              handleChangePage={handleChangePageCompany}
              handleChangeRowsPerPage={handleChangeRowsPerPageCompany}
            >
              {companysToSearchSupplier.map((item: any) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChangeSupplier(undefined, item);
                    setOpenDialogFour(false);
                    setPageCompany({
                      ...pageCompany,
                      page: 0,
                    });
                  }}
                >
                  <TableCell component="th" scope="row">
                    {item.cnpj}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.label}
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "5%" }}>
          <ButtonDefault
            label="Fechar"
            onClick={() => {
              setOpenDialogFour(false);
              setPageCompany({
                ...pageCompany,
                page: 0,
              });
            }}
          />
        </DialogActions>
      </DialogComponent>
    </>
  );
};

export default CreateOrUpdate;
