import httpClientRegister from "src/http/register";
import { iPage } from "src/interfaces/layout";

class attributesConsumer {
  static get(
    page: iPage,
    productId: string,
    orderBy?: any,
    description?: string,
    code?: string
  ) {
    const search = new URLSearchParams();
    search.append("PageNumber", page.page.toPrecision());
    search.append("PageSize", page.rowsPerPage.toPrecision());
    search.append("ProdutoId", productId);

    if (orderBy?.number > 0) {
      search.append("OrderByDesc", `${orderBy?.order}`);
      search.append("OrderIndex", `${orderBy?.number}`);
    }

    if (description) {
      search.append("ProdutoDescricao", `${description}`);
    }

    if (code) {
      search.append("ProdutoCodigo", `${code}`);
    }

    return httpClientRegister.get(`/ProdutosAtributos?${search.toString()}`);
  }
}

export default attributesConsumer;
