import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import Login from "src/pages/Login";
import PasswordRecovery from "src/pages/PasswordRecovery";
import PasswordReset from "src/pages/PasswordReset";
import { iMenuRoutes } from "src/interfaces/menuRoutes";
import { MenuRoutes } from "./menuRoutes";
import { localStorageStrings } from "src/constants/localStorageStings";
import User from "src/models/User";
import { iUser } from "src/interfaces/user";
import Providers from "src/Providers";
import { MultiLanguageRoute } from "./MultiLanguageRoute";

const RoutesWrapper = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MultiLanguageRoute path="/" />}>
          <Route path="/" element={<Login />} />
        </Route>

        <Route element={<MultiLanguageRoute path="/:lang" />}>
          <Route path="/:lang" element={<Login />} />
        </Route>

        <Route Component={PasswordRecovery} path="/:lang/password/recovery" />
        <Route Component={PasswordReset} path="/:lang/password/reset" />

        {/* Protected routes */}
        {MenuRoutes().map((menu: iMenuRoutes) => {
          return (
            <Route
              key={menu.path}
              path={menu.path}
              element={
                <ProtectedRoute path={menu.path}>
                  {menu.component}
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

const ProtectedRoute = ({
  children,
  path,
}: {
  children: any;
  path: string;
}) => {
  const userLogged = localStorage.getItem(localStorageStrings.userLogged);
  let user: iUser | any;

  if (userLogged) user = User.adapterToJson(userLogged);

  if (!user?.id) {
    return <Navigate to="/" replace />;
  }

  // TODO: remover toda essa parte depois
  if (user?.login === "gestao.einstein") {
    if (path !== "/power/bi/einsten")
      return <Navigate to="/power/bi/einsten" replace />;
  }

  // remover até aqui

  return <Providers>{children}</Providers>;
};

export default RoutesWrapper;
