/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { maskCnpj } from "src/functions/text";
import { iPage } from "src/interfaces/layout";
import ShoppingProcessDemandClientConsumer from "src/services/shoppingProcessDemandClient";

const ShoppingProcessDemandClientContext = createContext<any>({} as any);

export function ShoppingProcessDemandClientProvider({
  children,
}: {
  children: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [demand, setDemand] = useState<any[]>([]);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getShoppingProcessDemandClient = async (
    shoppingProccessId: string,
    companyId: string
  ) => {
    setIsError(false);
    setLoading(true);
    try {
      const response = await ShoppingProcessDemandClientConsumer.get(
        page,
        shoppingProccessId,
        companyId
      );

      if (response.status !== 200) throw response;

      setDemand({
        ...response.data,
        cnpj: maskCnpj(response.data.cnpj),
      });
    } catch (e) {
      setIsError(true);
      toast.error("Ops... identificamos um erro ao buscar as demandas!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getShoppingProcessDemandClient,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      demand,
      setDemand,
      isError,
      setIsError,
    };
  }, [page, loading, demand, isError]);

  return (
    <ShoppingProcessDemandClientContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessDemandClientContext.Provider>
  );
}

export default ShoppingProcessDemandClientContext;
