import { format } from "date-fns";
import { localStorageStrings } from "src/constants/localStorageStings";
import { iInvoicing } from "src/interfaces/invoicing";

export default class Invoicing implements iInvoicing {
  id: string;
  registerDate: string;
  invoiceValue: number;
  paymentDatePrev: string;
  dateProgInvoicing: string;
  emissionDateNF: string;
  numberNF: string;
  numberBoleto: string;
  dateBoleto: string;
  paymentDate: string;
  paymentValue: string;
  companyId: string;
  companyName?: string;
  status?: string;
  statusId?: string;
  periodoFatInicial?: string;
  periodoFatFinal?: string;

  constructor(
    id: string,
    registerDate: string,
    invoiceValue: number,
    paymentDatePrev: string,
    dateProgInvoicing: string,
    emissionDateNF: string,
    numberNF: string,
    numberBoleto: string,
    dateBoleto: string,
    paymentDate: string,
    paymentValue: string,
    companyId: string,
    companyName?: string,
    status?: string,
    statusId?: string,
    periodoFatInicial?: string,
    periodoFatFinal?: string
  ) {
    this.id = id;
    this.registerDate = registerDate;
    this.invoiceValue = invoiceValue;
    this.paymentDatePrev = paymentDatePrev;
    this.dateProgInvoicing = dateProgInvoicing;
    this.emissionDateNF = emissionDateNF;
    this.numberNF = numberNF;
    this.numberBoleto = numberBoleto;
    this.dateBoleto = dateBoleto;
    this.paymentDate = paymentDate;
    this.paymentValue = paymentValue;
    this.companyId = companyId;
    this.companyName = companyName;
    this.status = status;
    this.statusId = statusId;
    this.periodoFatInicial = periodoFatInicial;
    this.periodoFatFinal = periodoFatFinal;
  }

  public static adapterToClass(invoicing: any) {
    return new Invoicing(
      invoicing.id,
      invoicing.dataCadastro,
      invoicing.valorFatura,
      invoicing.dataPrevPagamento,
      invoicing.dataProgFaturamento,
      invoicing.dataEmissaoNF,
      invoicing.numeroNF,
      invoicing.numeroBoleto,
      invoicing.dataBoleto,
      invoicing.dataPagamento,
      invoicing.valorPagamento,
      invoicing.empresaId,
      invoicing.razaoSocial ?? "Não informado",
      invoicing.andamentoFaturamentoStatus ?? "Não informado",
      invoicing.andamentoFaturamentoStatusId ?? "",
      invoicing.periodoFatInicial ?? "",
      invoicing.periodoFatFinal ?? ""
    );
  }

  public static adapterToJson(invoicing: any) {
    return JSON.parse(invoicing);
  }

  public static adapterToBody(invoicing: iInvoicing) {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);
    const userJson = JSON.parse(`${userLogged}`);

    const data: any = {
      user: userJson.login,
      dataCadastro: format(new Date(), "yyyy-MM-dd"),
      PeriodoFatInicial: invoicing.periodoFatInicial,
      PeriodoFatFinal: invoicing.periodoFatFinal,
    };

    if (invoicing.id) {
      data["id"] = invoicing.id;
    }

    if (invoicing.invoiceValue) {
      data["valorFatura"] = Number(invoicing.invoiceValue.toFixed(2));
    }

    if (invoicing.paymentDatePrev) {
      data["dataPrevPagamento"] = invoicing.paymentDatePrev;
    }

    if (invoicing.dateProgInvoicing) {
      data["dataProgFaturamento"] = invoicing.dateProgInvoicing;
    }

    if (invoicing.emissionDateNF) {
      data["dataEmissaoNF"] = invoicing.emissionDateNF;
    }

    if (invoicing.numberNF) {
      data["numeroNF"] = invoicing.numberNF;
    }

    if (invoicing.numberBoleto) {
      data["numeroBoleto"] = invoicing.numberBoleto;
    }

    if (invoicing.dateBoleto) {
      data["dataBoleto"] = invoicing.dateBoleto;
    }

    if (invoicing.paymentDate) {
      data["dataPagamento"] = invoicing.paymentDate;
    }

    if (invoicing.paymentValue) {
      data["valorPagamento"] = Number(invoicing.paymentValue);
    }

    if (invoicing.companyId ?? null) {
      data["empresaId"] = invoicing.companyId ?? null;
    }

    return data;
  }
}
