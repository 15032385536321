/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ItemCustom, ItemCustomStart } from "src/components/ItemCustom";
import {
  Card,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ButtonInclude, ButtonSearch } from "src/components/ButtonsCommons";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { palette } from "src/theme";
import CircularLoading from "src/components/Loading";
import TableComponent from "src/components/TableComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogComponent from "src/components/Dialog";
import StagePurchasingProcess from "./StagePurchasingProcess";
import useLayout from "src/hooks/useLayout";
import { MaskStyle } from "src/styles/globalStyle";
import useShoppingProcessType from "src/hooks/useShoppingProcessType";
import useShoppingProcessPhases from "src/hooks/useShoppingProcessPhases";
import Swal from "sweetalert2";
import useCompany from "src/hooks/useCompany";
import useShoppingProccessStatus from "src/hooks/useShoppingProccessStatus";
import Empty from "src/components/Empty";
import { addHours, format } from "date-fns";
import { iShoppingProcessPhases } from "src/interfaces/shoppingProcessPhases";
import useShoppingProcessPhasesItem from "src/hooks/useShoppingProcessPhasesItem";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useTable from "src/hooks/useTable";

const header = [
  {
    id: "code",
    label: "Código",
    isOrder: true,
    order: 1,
  },
  {
    id: "description",
    label: "Descrição",
    isOrder: true,
    order: 2,
  },
  {
    id: "startDatePC",
    label: "Abertura",
    isOrder: true,
    order: 3,
  },
  {
    id: "endDatePC",
    label: "Encerramento",
    isOrder: true,
    order: 4,
  },
  {
    id: "valueTotal",
    label: "Valor Total",
    isOrder: true,
    order: 5,
  },
  {
    id: "type",
    label: "Tipo",
    isOrder: true,
    order: 6,
  },
  {
    id: "status",
    label: "Status",
    isOrder: true,
    order: 7,
  },
];

const Phases: React.FC = () => {
  const { openDialog, setOpenDialog, width } = useLayout();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { page: pageCompany, setPage, setSupplier, setBuyer } = useCompany();
  const { setFieldOrder, setOrder } = useTable();

  const { getShoppingProccessStatus, shoppingProccessStatus } =
    useShoppingProccessStatus();

  const {
    page,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    proccessNumber,
    setProccessNumber,
    registerDate,
    setRegisterDate,
    endDate,
    setEndDate,
    type,
    setType,
    status,
    setStatus,
    codeItemSearch,
    setCodeItemSearch,
    descriptionItemSearch,
    setDescriptionItemSearch,
    companyId,
    setCompanyId,
    description,
    setDescription,
    shoppingProcessPhases,
    handleSelected,
    handleDelete,
    setValue,
    setOrderBy,
    getShoppingProcessPhases,
    orderBy,
  } = useShoppingProcessPhases();

  const { shoppingProcesSelect } = useShoppingProcess();

  const {
    loading: loadingShoppingProcessType,
    getShoppingProcessPhaseType,
    shoppingProcessPhaseType,
  } = useShoppingProcessType();

  const { setShoppingProcessPhasesItems } = useShoppingProcessPhasesItem();

  useEffect(() => {
    setPage({
      ...pageCompany,
      rowsPerPage: 10,
    });
    setSupplier(true);
    setBuyer(true);
    setValue(0);

    setFieldOrder({
      id: "startDatePC",
      label: "Abertura",
      isOrder: true,
      order: 3,
    });
    setOrder("desc");

    setShoppingProcessPhasesItems([]);

    getShoppingProcessPhaseType();
    getShoppingProccessStatus();
  }, []);

  useEffect(() => {
    getShoppingProcessPhases(`${shoppingProcesSelect?.id}`);
  }, [orderBy]);

  if (loading || loadingShoppingProcessType) {
    return <CircularLoading />;
  }

  return (
    <>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>Processo: </FormLabel>
              ) : null}
              <TextField
                fullWidth
                size="small"
                id="process"
                name="process"
                label="Processo"
                variant="outlined"
                value={proccessNumber}
                onChange={(e) => setProccessNumber(e.target.value)}
              />
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>Código do item: </FormLabel>
              ) : null}
              <TextField
                size="small"
                id="codeItem"
                name="codeItem"
                label="Código do item"
                variant="outlined"
                value={codeItemSearch}
                onChange={(e) => setCodeItemSearch(e.target.value)}
                fullWidth
              />
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ minWidth: "15%" }}>
                  {translations[param]["type"]}:{" "}
                </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <Select
                  labelId="show_select_type"
                  id="show_select_type"
                  value={type}
                  size="small"
                  onChange={(event: SelectChangeEvent) =>
                    setType(event.target.value as string)
                  }
                >
                  <MenuItem value="select" disabled>
                    {width <= 1500
                      ? `${translations[param]["type"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                    :
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {shoppingProcessPhaseType.map((type) => (
                    <MenuItem key={type.id} value={`${type.id}`}>
                      {type.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>Descrição: </FormLabel>
              ) : null}
              <TextField
                size="small"
                id="description"
                name="description"
                label="Descrição"
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>Descrição do Item: </FormLabel>
              ) : null}
              <TextField
                size="small"
                id="descriptionItem"
                name="descriptionItem"
                label="Descrição do Item"
                variant="outlined"
                value={descriptionItemSearch}
                onChange={(e) => setDescriptionItemSearch(e.target.value)}
                fullWidth
              />
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={2} md={2}>
            <ItemCustomStart>
              {width >= 1500 ? (
                <FormLabel sx={{ minWidth: "30%" }}>De:</FormLabel>
              ) : null}
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={registerDate}
                    onChange={(e) => setRegisterDate(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </ItemCustomStart>
          </Grid>
          <Grid xs={2} sm={2} md={2}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ marginLeft: 1 }}>Até:</FormLabel>
              ) : null}
              <FormControl fullWidth>
                <MaskStyle>
                  <input
                    type="date"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    style={{ maxWidth: "94%" }}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>Empresa: </FormLabel>
              ) : null}
              <TextField
                size="small"
                id="company"
                name="company"
                label="Empresa"
                variant="outlined"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                fullWidth
              />
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={4} md={4}>
            <ItemCustom>
              {width >= 1500 ? (
                <FormLabel sx={{ width: "20%" }}>
                  {translations[param]["status"]}:
                </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <Select
                  labelId="show_select_type"
                  id="show_select_status"
                  value={status}
                  size="small"
                  required
                  onChange={(event: SelectChangeEvent) =>
                    setStatus(event.target.value as string)
                  }
                >
                  <MenuItem value="select" disabled>
                    {width >= 1500
                      ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {shoppingProccessStatus.map((statusItem) => (
                    <MenuItem key={statusItem.id} value={`${statusItem.id}`}>
                      {statusItem.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={2} sm={12} md={12} display="flex" justifyContent="end">
            <ItemCustom>
              <ButtonSearch
                label="Pesquisar"
                onClick={() => {}}
                size="medium"
              />
            </ItemCustom>
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ padding: 2, marginTop: 3, width: "100%" }}>
        <FormLabel>Etapas</FormLabel>
        <Divider
          sx={{ marginBottom: 2, backgroundColor: palette.primary.main }}
        />
        <Grid sx={{ marginBottom: 2 }}>
          <ItemCustom>
            <ButtonInclude
              label="Incluir"
              onClick={() => {
                handleSelected(null);
                setOpenDialog(true);
              }}
              disabled={!shoppingProcesSelect?.id}
            />
          </ItemCustom>
        </Grid>
        {loading ? (
          <CircularLoading />
        ) : shoppingProcessPhases.length === 0 ? (
          <Empty />
        ) : (
          <>
            <TableComponent
              header={header}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              funtionToOrder={(item) =>
                item?.orderBackend &&
                setOrderBy({
                  number: item?.orderBackend,
                  order: item?.order === "asc" ? true : false,
                })
              }
            >
              {shoppingProcessPhases.map((item: iShoppingProcessPhases) => (
                <TableRow
                  hover
                  key={item.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                  >
                    {item.number}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                    sx={{ width: "15%" }}
                  >
                    {item.description}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                    sx={{ width: "20%" }}
                  >
                    {format(
                      addHours(new Date(item.startDatePC), 3),
                      "dd/MM/yyyy"
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                  >
                    {format(
                      addHours(new Date(item.endDatePC), 3),
                      "dd/MM/yyyy"
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                  >
                    {item?.valueTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                  >
                    {item.shoppingProcessPhaseType}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      handleSelected(item);
                      setOpenDialog(true);
                    }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      Swal.fire({
                        title: "Atenção!",
                        text: "A etapa do processo de compra será deletado, deseja continuar ? ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: palette.primary.main,
                        cancelButtonColor: palette.error.main,
                        confirmButtonText: "Sim, deletar!",
                        cancelButtonText: "Não",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDelete(item, shoppingProcesSelect?.id ?? "");
                        }
                      })
                    }
                  >
                    <DeleteIcon color="error" />
                  </TableCell>
                </TableRow>
              ))}
            </TableComponent>
          </>
        )}
      </Card>

      <DialogComponent open={openDialog} handleClose={() => {}}>
        <StagePurchasingProcess />
      </DialogComponent>
    </>
  );
};

export default Phases;
