/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ButtonClosedForm, ButtonSearch } from "src/components/ButtonsCommons";
import {
  Card,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Grid,
} from "@mui/material";
import { MaskStyle } from "src/styles/globalStyle";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import useRequest from "src/hooks/useRequest";
import useRequestStatus from "src/hooks/useRequestStatus";
import useLayout from "src/hooks/useLayout";

const Search = ({
  shoppingProccessId,
}: {
  shoppingProccessId?: string | null | undefined;
}) => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const { width } = useLayout();

  const {
    getRequests,
    statusFilter,
    setStatusFilter,
    proccessNumberFilter,
    setProccessNumberFilter,
    descriptionFilter,
    setDescriptionFilter,
    requestNumberFilter,
    setRequestNumberFilter,
    companyFilter,
    setCompanyFilter,
    NfFilter,
    setNfFilter,
    codeItemFilter,
    setCodeItemFilter,
    descriptionItemFilter,
    setDescriptionItemFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    startDateFilterNf,
    setStartDateFilterNf,
    endDateFilterNf,
    setEndDateFilterNf,
    cleanFilters,
    setLoading,
    typeFilterDate,
    setTypeFilterDate,
  } = useRequest();

  const { requestStatus } = useRequestStatus();

  useEffect(() => {
    setProccessNumberFilter("");
  }, []);

  return (
    <>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          {!shoppingProccessId && (
            <Grid item xs={2} sm={4} md={4}>
              <Grid container alignItems="center">
                {width >= 1400 && (
                  <Grid item md={3}>
                    <FormLabel>{translations[param]["proccess"]}:</FormLabel>
                  </Grid>
                )}
                <Grid item xs md={9}>
                  <TextField
                    size="small"
                    id="process"
                    name="process"
                    fullWidth
                    value={proccessNumberFilter}
                    onChange={(e) => setProccessNumberFilter(e.target.value)}
                    label={`N° ${translations[param]["proccess"]}`}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel>{translations[param]["request"]}:</FormLabel>
                </Grid>
              )}
              <Grid item md={9}>
                <TextField
                  size="small"
                  fullWidth
                  id="requestNumber"
                  name="requestNumber"
                  value={requestNumberFilter}
                  onChange={(e) => setRequestNumberFilter(e.target.value)}
                  label={`N° ${translations[param]["request"]}`}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel>{translations[param]["item_code"]}:</FormLabel>
                </Grid>
              )}
              <Grid item xs md={9}>
                <TextField
                  size="small"
                  id="codeItem"
                  name="codeItem"
                  label={translations[param]["item_code"]}
                  value={codeItemFilter}
                  onChange={(e) => setCodeItemFilter(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          {!shoppingProccessId && (
            <Grid item xs={2} sm={4} md={4}>
              <Grid container alignItems="center">
                {width >= 1400 && (
                  <Grid item md={3}>
                    <FormLabel>
                      {translations[param]["description"]}:{" "}
                    </FormLabel>
                  </Grid>
                )}
                <Grid item xs md={9}>
                  <TextField
                    size="small"
                    fullWidth
                    id="description"
                    name="description"
                    label={translations[param]["description"]}
                    value={descriptionFilter}
                    onChange={(e) => setDescriptionFilter(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel sx={{ marginRight: 1 }}>NF: </FormLabel>
                </Grid>
              )}
              <Grid item xs md={9}>
                <TextField
                  size="small"
                  id="nfNumber"
                  name="nfNumber"
                  label={translations[param]["number_nf"]}
                  value={NfFilter}
                  onChange={(e) => setNfFilter(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel>
                    {translations[param]["item_description"]}:
                  </FormLabel>
                </Grid>
              )}
              <Grid item xs md={9}>
                <TextField
                  size="small"
                  id="descriptionItem"
                  name="descriptionItem"
                  label={translations[param]["item_description"]}
                  value={descriptionItemFilter}
                  onChange={(e) => setDescriptionItemFilter(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel>{translations[param]["company"]}:</FormLabel>
                </Grid>
              )}
              <Grid item xs md={9}>
                <TextField
                  size="small"
                  id="company"
                  name="company"
                  label={translations[param]["enter_your_cnpj_or_name"]}
                  value={companyFilter}
                  onChange={(e) => setCompanyFilter(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Grid container alignItems="center">
              {width >= 1400 && (
                <Grid item md={3}>
                  <FormLabel>{translations[param]["status"]}:</FormLabel>
                </Grid>
              )}
              <Grid item xs md={9}>
                <Select
                  labelId="show_select_type"
                  id="show_select_status"
                  value={statusFilter}
                  size="small"
                  fullWidth
                  onChange={(event: SelectChangeEvent) =>
                    setStatusFilter(event.target.value as string)
                  }
                >
                  <MenuItem value="select" disabled>
                    {width <= 1400
                      ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {requestStatus?.map((item) => (
                    <MenuItem key={item.id} value={`${item.id}`}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={4} md={4} display="flex" alignItems="center">
            <Grid md={3}>
              {width >= 1400 && (
                <FormLabel>{translations[param]["date"]}:</FormLabel>
              )}
            </Grid>
            <Grid md={9} display="flex">
              <Select
                labelId="show_select_type"
                id="show_select_status"
                value={typeFilterDate}
                size="small"
                fullWidth
                onChange={(event: SelectChangeEvent) =>
                  setTypeFilterDate(event.target.value as string)
                }
              >
                <MenuItem value="select" disabled>
                  {width <= 1400
                    ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                    : translations[param]["select"]}
                </MenuItem>
                <MenuItem value="">...</MenuItem>
                <MenuItem value="emission">Data de Emissão</MenuItem>
                <MenuItem value="emissonNF">Data de Emissão NF</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid item md={12} display="flex" justifyContent="end">
            {typeFilterDate === "emission" ? (
              <Grid display="flex">
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={startDateFilter}
                    onChange={(e) => setStartDateFilter(e.target.value)}
                    style={{ maxWidth: "95%", marginLeft: "5%" }}
                  />
                </MaskStyle>
                <MaskStyle>
                  <input
                    type="date"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={endDateFilter}
                    onChange={(e) => setEndDateFilter(e.target.value)}
                    style={{ maxWidth: "95%", marginLeft: "5%" }}
                  />
                </MaskStyle>
              </Grid>
            ) : (
              <Grid display="flex">
                <MaskStyle>
                  <input
                    type="date"
                    name="begin_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={startDateFilterNf}
                    onChange={(e) => setStartDateFilterNf(e.target.value)}
                    style={{ maxWidth: "95%", marginLeft: "5%" }}
                  />
                </MaskStyle>
                <MaskStyle>
                  <input
                    type="date"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    min="1970-01-01"
                    value={endDateFilterNf}
                    onChange={(e) => setEndDateFilterNf(e.target.value)}
                    style={{ maxWidth: "95%", marginLeft: "5%" }}
                  />
                </MaskStyle>
              </Grid>
            )}
          </Grid>

          <Grid
            item
            xs={2}
            sm={12}
            md={12}
            display="flex"
            justifyContent="flex-end"
            marginTop="10px"
          >
            <ButtonClosedForm
              label={translations[param]["clear_form"]}
              onClick={cleanFilters}
            />
            <ButtonSearch
              label={translations[param]["search"]}
              onClick={async () => {
                setLoading(true);
                await getRequests();
              }}
              size="medium"
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default Search;
