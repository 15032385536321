/* eslint-disable react-hooks/exhaustive-deps */
import { addHours, format } from "date-fns";
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { localStorageStrings } from "src/constants/localStorageStings";
import useLayout from "src/hooks/useLayout";
import useShoppingProcessPhasesItem from "src/hooks/useShoppingProcessPhasesItem";
import {
  iShoppingProcessPhases,
  iShoppingProcessPhasesContextProps,
  iShoppingProcessPhasesPage,
} from "src/interfaces/shoppingProcessPhases";
import ShoppingProcessPhases from "src/models/ShoppingProcessPhases";
import shoppingProcessConsumer from "src/services/shoppingProcess";
import ShoppingProcessPhasesConsumer from "src/services/shoppingProcessPhases";

const ShoppingProcessPhasesContext =
  createContext<iShoppingProcessPhasesContextProps>(
    {} as iShoppingProcessPhasesContextProps
  );

export function ShoppingProcessPhasesProvider({ children }: { children: any }) {
  const userLogged = localStorage.getItem(localStorageStrings.userLogged);
  const userJson = JSON.parse(`${userLogged}`);

  const { setOpenDialogSecondary } = useLayout();

  const { setProccessBuyerPhaseId, sumValueTotalItems, setSumValueTotalItems } =
    useShoppingProcessPhasesItem();

  const [loading, setLoading] = useState<boolean>(false);
  const [showButtonClosed, setShowButtonClosed] = useState<boolean>(false);
  const [shoppingProcessPhasesToSearch, setShoppingProcessPhasesToSearch] =
    useState<Array<any>>([]);
  const [shoppingProcessPhases, setShoppingProcessPhases] = useState<
    Array<iShoppingProcessPhases>
  >([]);
  const [page, setPage] = useState<iShoppingProcessPhasesPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [shoppingProcesPhasesSelect, setShoppingProcesPhasesSelect] =
    useState<iShoppingProcessPhases | null>(null);
  const [statusProgress, setStatusProgress] = useState<any[]>([]);
  const [observationStatusPC, setObservationStatusPC] = useState<string>("");
  const [proccessNumber, setProccessNumber] = useState<string>("");
  const [registerDate, setRegisterDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [type, setType] = useState<string>("select");
  const [status, setStatus] = useState<string>("select");
  const [description, setDescription] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const [companyId, setCompanyId] = useState<string>("");
  const [codeItemSearch, setCodeItemSearch] = useState<string>("");
  const [descriptionItemSearch, setDescriptionItemSearch] =
    useState<string>("");
  const [orderBy, setOrderBy] = useState<any>({ number: 3, order: true });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProcessPhases = async (shoppingProcesSelectId: string) => {
    setLoading(true);
    try {
      const response = await ShoppingProcessPhasesConsumer.get(
        page,
        shoppingProcesSelectId,
        proccessNumber,
        companyId,
        codeItemSearch,
        descriptionItemSearch,
        status,
        type,
        registerDate,
        endDate,
        orderBy
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcessPhases.adapterToClass(item)
      );

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      setShoppingProcessPhases(data);
      setShoppingProcessPhasesToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item.id,
            label: item.descricao,
          };
        })
      );

      return data;
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as etapas de processos de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = async (
    shoppingProcessPhases: iShoppingProcessPhases | null
  ) => {
    if (!shoppingProcessPhases) {
      localStorage.removeItem("shoppingProcessPhases");
    } else {
      localStorage.setItem(
        "shoppingProcessPhases",
        JSON.stringify(
          ShoppingProcessPhases.adapterToBody(shoppingProcessPhases)
        )
      );
    }

    setShoppingProcesPhasesSelect(shoppingProcessPhases);
    setProccessBuyerPhaseId(shoppingProcessPhases?.id ?? "");
    setProccessNumber(shoppingProcessPhases?.number ?? "");
    setDescription(shoppingProcessPhases?.description ?? "");
    setType(shoppingProcessPhases?.shoppingProcessPhaseTypeId ?? "select");

    if (
      shoppingProcessPhases?.valueTotal &&
      shoppingProcessPhases?.valueTotal > 0
    ) {
      setSumValueTotalItems(shoppingProcessPhases?.valueTotal);
    } else {
      setSumValueTotalItems(0);
    }

    setStatus(shoppingProcessPhases?.statusId ?? "select");

    if (shoppingProcessPhases?.startDatePC) {
      setRegisterDate(
        format(
          addHours(new Date(`${shoppingProcessPhases?.startDatePC}`), 3),
          "yyyy-MM-dd"
        )
      );
    } else {
      setRegisterDate("");
    }

    if (shoppingProcessPhases?.endDatePC) {
      setEndDate(
        format(
          addHours(new Date(`${shoppingProcessPhases?.endDatePC}`), 3),
          "yyyy-MM-dd"
        )
      );
    } else {
      setEndDate("");
    }

    if (!shoppingProcessPhases?.id) return;

    await getStatusInProgress(shoppingProcessPhases);
  };

  const handleDelete = async (
    shoppingProcessPhases: iShoppingProcessPhases,
    shoppingProcesSelectId: string
  ) => {
    try {
      setLoading(true);

      const response = await ShoppingProcessPhasesConsumer.deleted(
        `${shoppingProcessPhases?.id}`
      );

      if (response.status !== 200) throw response;

      setPage({
        ...page,
        page: 0,
      });

      await getStatusInProgress(shoppingProcessPhases);
      await getShoppingProcessPhases(shoppingProcesSelectId);

      toast.success("Etapa de processo de compra deletada com sucesso!");
    } catch (e: any) {
      toast.error(
        e?.response?.data?.nessage ??
          "Erro ao deletar a etapa de processo de compra!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalve = async (shoppingProcesSelectId: string) => {
    if (!status || status === "select")
      return toast.warning("O campo de status é obrigatório!");

    setLoading(true);

    try {
      const body = new ShoppingProcessPhases(
        null,
        proccessNumber,
        description,
        new Date(registerDate),
        new Date(endDate),
        "",
        sumValueTotalItems,
        type,
        "",
        shoppingProcesSelectId,
        status,
        [],
        status
      );

      const response = await ShoppingProcessPhasesConsumer.created(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      body.id = response?.data?.id;

      handleSelected(body);
      setShoppingProcesPhasesSelect(body);

      const statusPC = {
        processoCompraStatusId: status,
        usuarioSistemaResponsavel: userJson?.login,
        observacao: observationStatusPC,
        processoCompraEtapaId:
          shoppingProcesPhasesSelect?.id ?? response?.data?.id,
      };

      const respStatus = await shoppingProcessConsumer.statusPC(statusPC);

      if (respStatus.status !== 200 && respStatus.status !== 201) {
        toast.warning("Ocorreu um erro ao salvar o andamento do status!");
      }

      await getStatusInProgress({
        id: shoppingProcesPhasesSelect?.id
          ? shoppingProcesPhasesSelect?.id
          : response?.data?.id,
      });
      await getShoppingProcessPhases(shoppingProcesSelectId);

      toast.success("Etapa cadastrada com sucesso!");
      setOpenDialogSecondary(true);
    } catch {
      toast.error(
        "Ops... Ocorreu um erro ao salvar a etapa processo de compras!"
      );
    } finally {
      setLoading(false);
    }
  };

  const getStatusInProgress = async (shoppingProcessPhases: any) => {
    const statusProgressData = await shoppingProcessConsumer.getStatusPC({
      shoppingProccessPhaseId: `${shoppingProcessPhases?.id}`,
    });

    if (statusProgressData?.status !== 20) setStatusProgress([]);

    setStatusProgress(statusProgressData.data.items);
  };

  const handleUpdate = async (
    shoppingProcesSelectId: string,
    newTotal: any
  ) => {
    if (!status || status === "select")
      return toast.warning("O campo de status é obrigatório!");

    setLoading(true);

    try {
      const body = new ShoppingProcessPhases(
        shoppingProcesPhasesSelect?.id ?? "",
        proccessNumber,
        description,
        new Date(registerDate),
        new Date(endDate),
        "",
        newTotal,
        type,
        "",
        shoppingProcesSelectId,
        status,
        shoppingProcesPhasesSelect?.items,
        status
      );

      const response = await ShoppingProcessPhasesConsumer.updated(body);

      if (response.status !== 200 && response.status !== 201) throw response;

      const statusPC = {
        processoCompraStatusId: status,
        usuarioSistemaResponsavel: userJson?.login,
        observacao: observationStatusPC,
        processoCompraEtapaId:
          shoppingProcesPhasesSelect?.id ?? response?.data?.id,
      };

      const respStatus = await shoppingProcessConsumer.statusPC(statusPC);

      if (respStatus.status !== 200 && respStatus.status !== 201) {
        toast.warning("Ocorreu um erro ao salvar o andamento do status!");
      }

      await getStatusInProgress({
        id: shoppingProcesPhasesSelect?.id
          ? shoppingProcesPhasesSelect?.id
          : response?.data?.id,
      });
      await getShoppingProcessPhases(shoppingProcesSelectId);

      toast.success("Etapa alterada com sucesso!");
    } catch (e) {
      toast.error(
        "Ops... tivemos um problema ao alterar a etapa processo de compras!"
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      getShoppingProcessPhases,
      loading,
      setLoading,
      shoppingProcessPhases,
      handleChangePage,
      handleChangeRowsPerPage,
      page,
      shoppingProcesPhasesSelect,
      setShoppingProcesPhasesSelect,
      handleSelected,
      handleDelete,
      handleNewSalve,
      handleUpdate,
      shoppingProcessPhasesToSearch,
      proccessNumber,
      setProccessNumber,
      registerDate,
      setRegisterDate,
      endDate,
      setEndDate,
      type,
      setType,
      status,
      setStatus,
      codeItemSearch,
      setCodeItemSearch,
      descriptionItemSearch,
      setDescriptionItemSearch,
      companyId,
      setCompanyId,
      description,
      setDescription,
      value,
      setValue,
      statusProgress,
      setStatusProgress,
      observationStatusPC,
      setObservationStatusPC,
      showButtonClosed,
      setShowButtonClosed,
      orderBy,
      setOrderBy,
    };
  }, [
    page,
    loading,
    shoppingProcessPhases,
    shoppingProcessPhasesToSearch,
    proccessNumber,
    registerDate,
    endDate,
    type,
    status,
    codeItemSearch,
    descriptionItemSearch,
    companyId,
    description,
    value,
    statusProgress,
    observationStatusPC,
    showButtonClosed,
    orderBy,
  ]);

  return (
    <ShoppingProcessPhasesContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessPhasesContext.Provider>
  );
}

export default ShoppingProcessPhasesContext;
