/* eslint-disable react-hooks/exhaustive-deps */
import { useMediaQuery, useTheme } from "@mui/material";
import { createContext, useMemo, useState } from "react";
import { iPage } from "src/interfaces/layout";

export interface FormValues {
  descricaoProcesso: string;
  numeroProcesso: string;
  cnpj: string;
  empresa: string;
  compradorResponsavel: string;
  email: string;
  rede: string;
  telefone: string;
  cartaConvite: string;
  codigo: string;
  descricao: string;
  codigoCliente: string;
  descricaoCliente: string;
  quantidade: string;
  valorUltCompra: string;
  valorAlvo: string;
}

const DemandCollectionFormContext = createContext<any>({} as any);

export function DemandCollectionFormProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(Array(3).fill(false));
  const [formValues, setFormValues] = useState<FormValues>({
    descricaoProcesso: "",
    numeroProcesso: "",
    cnpj: "",
    empresa: "",
    compradorResponsavel: "",
    email: "",
    rede: "",
    telefone: "",
    cartaConvite: "",
    codigo: "",
    descricao: "",
    codigoCliente: "",
    descricaoCliente: "",
    quantidade: "",
    valorUltCompra: "",
    valorAlvo: "",
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const [itemSections, setItemSections] = useState<FormValues[]>([]);
  const [indicacaoItensSections, setIndicacaoItensSections] = useState<
    FormValues[]
  >([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery("(min-width:1400px)");

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleInputChange = (event: any, index: number, section: string) => {
    const { name, value } = event.target;

    const updateSections = (sections: FormValues[]) => {
      const updatedSections = [...sections];
      updatedSections[index] = { ...updatedSections[index], [name]: value };
      return updatedSections;
    };

    if (section === "item") {
      setItemSections(updateSections(itemSections));
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections(updateSections(indicacaoItensSections));
    }
  };

  const handleAddSection = (section: string) => {
    if (section === "item") {
      setItemSections([...itemSections, {} as FormValues]);
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections([...indicacaoItensSections, {} as FormValues]);
    }
  };

  const handleRemoveSection = (index: number, section: string) => {
    if (section === "item") {
      setItemSections(itemSections.filter((_, i) => i !== index));
    } else if (section === "indicacaoItens") {
      setIndicacaoItensSections(
        indicacaoItensSections.filter((_, i) => i !== index)
      );
    }
  };

  const handleToggle = (index: any) => {
    setOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      isMobile,
      handleDrawerToggle,
      selectedTab,
      handleTabChange,
      drawerOpen,
      isLargeScreen,
      formValues,
      setFormValues,
      handleInputChange,
      handleToggle,
      open,
      handleAddSection,
      indicacaoItensSections,
      handleRemoveSection,
    };
  }, [
    page,
    loading,
    isMobile,
    selectedTab,
    drawerOpen,
    isLargeScreen,
    formValues,
    open,
    indicacaoItensSections,
  ]);

  return (
    <DemandCollectionFormContext.Provider value={contextValue}>
      {children}
    </DemandCollectionFormContext.Provider>
  );
}

export default DemandCollectionFormContext;
