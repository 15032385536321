/* eslint-disable react-hooks/exhaustive-deps */
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { palette } from "src/theme";
import useShoppingProccessDemand from "src/hooks/useShoppingProccessDemand";
import { ButtonDefault, ButtonSalveForm } from "src/components/ButtonsCommons";
import useLayout from "src/hooks/useLayout";
import translations from "src/_i18n/translations.json";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import SearchIcon from "@mui/icons-material/Search";
import { formatCurrency } from "src/functions/text";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import useProduct from "src/hooks/useProduct";
import { toast } from "react-toastify";

const header = [
  {
    id: "description",
    label: "Descrição",
    isOrder: false,
    order: 1,
  },
  {
    id: "content",
    label: "Conteúdo",
    isOrder: false,
    order: 2,
  },
  {
    id: "type",
    label: "Tipo",
    isOrder: false,
    order: 3,
  },
  {
    id: "mandatory",
    label: "Obrigatório",
    isOrder: false,
    order: 4,
  },
  {
    id: "select",
    label: "Selecionar",
    isOrder: false,
    order: 5,
  },
];

const DemandItem: React.FC = () => {
  const { lang } = useParams();
  const { width, setOpenDialogSecondary, setClick } = useLayout();
  const {
    setIsDialogOpen,
    demand,
    setDemand,
    saveNewDemand,
    loading,
    updateDemand,
    getAttributes,
    setAttributes,
    attributes,
    pageAttributes,
    handleChangePageAttrinutes,
    handleChangeRowsPerPageAttributes,
    attributesSelect,
    setAttributesSelect,
    loadingAttributes,
    descriptionAttributeSearch,
    setDescriptionAttributeSearch,
  } = useShoppingProccessDemand();

  const {
    productsToSearch,
    codeSearch,
    setCodeSearch,
    descriptionSearch,
    setDescriptionSearch,
    loading: loadingProduct,
    page: pageProduct,
    setPage: setPageProduct,
    getProducts,
  } = useProduct();

  const { shoppingProcesSelect } = useShoppingProcess();

  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  useEffect(() => {
    if (codeSearch.length >= 4 || descriptionSearch.length >= 4) {
      getProducts();
    } else if (codeSearch.length === 0 || descriptionSearch.length === 0) {
      getProducts();
    }
  }, [codeSearch, descriptionSearch]);

  const Header = () => (
    <>
      <DialogTitle id="alert-dialog-title">
        Item de Coleta de Demanda
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {
          setDemand(null);
          setIsDialogOpen(false);
          setAttributes([]);
          setAttributesSelect([]);
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider sx={{ backgroundColor: palette.primary.main }} />
    </>
  );

  if (loading) {
    <>
      <Header />
      <CircularLoading />
    </>;
  }

  return (
    <>
      <Header />
      <DialogContent>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }} spacing={1}>
          {loadingProduct ? (
            <Grid xs={12} display="flex" justifyContent="center">
              <CircularLoading />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{translations[param]["code"]}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 10.7 : 12}>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="show_select_code"
                    options={productsToSearch}
                    value={demand}
                    getOptionLabel={(option) => {
                      if (option?.code)
                        return `${option.code} - ${option.label}`;

                      return "";
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setDemand({
                          ...demand,
                          produtoId: value.id,
                          produtoCodigo: value.code,
                          code: value.code,
                          produtoDescricao: value.label,
                          label: value.label,
                        });

                        getAttributes(value?.id);
                      } else {
                        setDemand({
                          ...demand,
                          produtoId: "",
                          produtoCodigo: "",
                          code: "",
                          label: "",
                          produtoDescricao: "",
                        });

                        setAttributes([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setCodeSearch(e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.3}>
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => {
                      setDescriptionSearch("");
                      setOpenDialogSecondary(true);
                      setClick(true);
                      setPageProduct({
                        ...pageProduct,
                        page: 0,
                        total: 0,
                      });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" alignItems="center">
                {width >= 1500 ? (
                  <Grid item xs={1}>
                    <FormLabel>{translations[param]["description"]}</FormLabel>
                  </Grid>
                ) : null}
                <Grid item xs={width >= 1500 ? 10.7 : 12}>
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="show_select_code"
                    options={productsToSearch}
                    value={demand}
                    getOptionLabel={(option) => {
                      if (option?.label)
                        return `${option.code} - ${option.label}`;

                      return "";
                    }}
                    onChange={(_: any, value: any) => {
                      if (value?.id) {
                        setDemand({
                          ...demand,
                          produtoId: value.id,
                          produtoDescricao: value.label,
                          label: value.label,
                          produtoCodigo: value.code,
                          code: value.code,
                        });

                        getAttributes(value?.id);
                      } else {
                        setDemand({
                          ...demand,
                          produtoId: "",
                          produtoDescricao: "",
                          label: "",
                          produtoCodigo: "",
                          code: "",
                        });

                        setAttributes([]);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione:"
                        size="small"
                        required
                        onChange={(e) => setDescriptionSearch(e.target.value)}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={0.3}>
                  <IconButton
                    aria-label="search"
                    color="primary"
                    onClick={() => {
                      setCodeSearch("");
                      setOpenDialogSecondary(true);
                      setClick(true);
                      setPageProduct({
                        ...pageProduct,
                        page: 0,
                        total: 0,
                      });
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item xs={12} display="flex" alignItems="center">
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>{`${translations[param]["description"]} Detalhada`}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 11 : 12}>
              <TextField
                id="observations"
                label={translations[param]["description"]}
                multiline
                rows={2}
                value={demand?.descricaoDetalhada ?? ""}
                onChange={(e) => {
                  setDemand({
                    ...demand,
                    descricaoDetalhada: e.target.value,
                  });
                }}
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} display="flex" alignItems="center">
            {width >= 1500 ? (
              <Grid item xs={1}>
                <FormLabel>Quantidade Estimada</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 5 : 6}>
              <TextField
                id="quantity"
                label="Quantidade Estimada"
                value={demand?.quantidade ?? ""}
                onChange={(e) => {
                  setDemand({
                    ...demand,
                    quantidade: e.target.value,
                  });
                }}
                fullWidth
                size="small"
              />
            </Grid>
            {width >= 1500 ? (
              <Grid item xs={1} style={{ marginLeft: "1%" }}>
                <FormLabel>{`${translations[param]["value"]} ${translations[param]["reference"]}`}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={width >= 1500 ? 5 : 6} style={{ marginLeft: "1%" }}>
              <TextField
                id="value"
                label={`${translations[param]["value"]} ${translations[param]["reference"]}`}
                value={demand?.valorReferencia}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => {
                  e = formatCurrency(e);

                  setDemand({
                    ...demand,
                    valorReferencia: e.target.value,
                  });
                }}
                fullWidth
                size="small"
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="start"
          >
            Atributos
          </Grid>

          <Grid
            item
            xs={11}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            {width >= 1500 ? (
              <Grid item xs={0.7}>
                <FormLabel>{`${translations[param]["search"]}`}</FormLabel>
              </Grid>
            ) : null}
            <Grid item xs={3}>
              <TextField
                id="value"
                label={`${translations[param]["search"]} Atributo`}
                value={descriptionAttributeSearch}
                onChange={(e) => setDescriptionAttributeSearch(e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={0.3}>
              <IconButton
                aria-label="search"
                color="primary"
                onClick={() => {
                  getAttributes(demand?.produtoId);
                }}
              >
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {loadingAttributes ? (
              <CircularLoading />
            ) : attributes.length === 0 ? (
              <Empty />
            ) : (
              <TableComponent
                header={header}
                page={pageAttributes}
                handleChangePage={handleChangePageAttrinutes}
                handleChangeRowsPerPage={handleChangeRowsPerPageAttributes}
                isPagination={false}
              >
                {attributes.map((item: any) => (
                  <TableRow
                    hover
                    key={item.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.descricao}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.descricaoDetalhada}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      {item?.descricaoTipoDado}
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={attributesSelect.some(
                          (attrSelect: any) =>
                            attrSelect.produtoId === item.produtoId &&
                            attrSelect.obrigatorio
                        )}
                        onChange={(e) => {
                          const isSelect = e.target.checked;
                          const newAttributes = attributesSelect.filter(
                            (el: any) => el.atributosProdutoId !== item.id
                          );

                          const attribute = attributesSelect.find(
                            (el: any) => el.atributosProdutoId === item.id
                          );

                          if (!attribute) {
                            toast.info(
                              "Favor, selecione um atrbibuto antes de selecionar a obrigatoriedade!"
                            );
                            return;
                          }

                          if (isSelect) {
                            attribute["obrigatorio"] = true;
                            setAttributesSelect([...newAttributes, attribute]);
                            return;
                          }

                          attribute["obrigatorio"] = false;
                          setAttributesSelect([...newAttributes, attribute]);
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" onClick={() => {}}>
                      <Checkbox
                        id="active"
                        name="active"
                        size="small"
                        checked={attributesSelect.some(
                          (attrSelect: any) =>
                            attrSelect.produtoId === item.produtoId
                        )}
                        onChange={(e) => {
                          const isSelect = e.target.checked;

                          if (isSelect) {
                            setAttributesSelect([
                              ...attributesSelect,
                              {
                                processoCompraDemandaItensId: demand?.id,
                                atributosProdutoId: item.id,
                                obrigatorio: false,
                                checked: true,
                                produtoId: item?.produtoId,
                              },
                            ]);
                            return;
                          }

                          const newAttributes = attributesSelect.filter(
                            (el: any) => el.atributosProdutoId !== item.id
                          );

                          setAttributesSelect(newAttributes);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableComponent>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: "5%" }}>
        {demand?.id ? (
          <ButtonSalveForm
            label={translations[param]["update"]}
            colorIcon="success"
            onClick={async () => {
              await updateDemand(shoppingProcesSelect);
              setCodeSearch("");
              setDescriptionSearch("");
            }}
          />
        ) : (
          <ButtonSalveForm
            label={translations[param]["save"]}
            colorIcon="success"
            onClick={async () => {
              await saveNewDemand(shoppingProcesSelect);
              setCodeSearch("");
              setDescriptionSearch("");
            }}
          />
        )}
        <ButtonDefault
          label="Fechar"
          onClick={() => {
            setDemand(null);
            setIsDialogOpen(false);
            setAttributes([]);
            setAttributesSelect([]);
          }}
        />
      </DialogActions>
    </>
  );
};

export default DemandItem;
