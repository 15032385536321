/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useProductCompany from "src/hooks/useProductCompany";
import useRequest from "src/hooks/useRequest";
import { iInvoices } from "src/interfaces/invoices";
import { iPage } from "src/interfaces/layout";
import { iRequests } from "src/interfaces/requests";
import Invoices from "src/models/Invoices";
import invoicesConsumer from "src/services/invoices";
import { addDays, format } from "date-fns";
import useLayout from "src/hooks/useLayout";

const InvoicesContext = createContext<any>({} as any);

export function InvoicesProvider({ children }: { children: any }) {
  const { setOpenDialog, setDisableButtons } = useLayout();
  const { requestSelect } = useRequest();
  const { getProducts, page: pageProductCompany } = useProductCompany();

  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<Array<iInvoices>>([]);
  const [invoiceSelect, setInvoiceSelect] = useState<iInvoices | null>(null);
  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [number, setNumber] = useState<string | null>("");
  const [value, setValue] = useState<string | null>("");
  const [netValue, setNetValue] = useState<string | null>("");
  const [registerDate, setRegisterDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [paymentPrevisionDate, setPaymentPrevisionDate] = useState<string>("");
  const [emissor, setEmissor] = useState<string>("");
  const [emissorName, setEmissorName] = useState<string>("");
  const [tomador, setTomador] = useState<string>("");
  const [tomadorName, setTomadorName] = useState<string>("");
  const [orderBy, setOrderBy] = useState<any>({ number: 1, order: true });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getInvoices = async (requestSelect: iRequests) => {
    setLoading(true);

    try {
      const response = await invoicesConsumer.get(
        page,
        requestSelect?.id,
        `${number}`,
        `${value}`,
        `${registerDate}`,
        `${endDate}`,
        orderBy
      );

      if (response.status !== 200) throw response;

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      const data = response.data.items?.map((item: any) =>
        Invoices.adapterToClass(item)
      );

      setInvoices(data);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar as notas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleNewSalve = async () => {
    if (!number) {
      return toast.info("Favor informe todos os campos obrigatórios!");
    } else if (!requestSelect) {
      return toast.info("Favor informe todos os campos obrigatórios!");
    } else if (!registerDate) {
      return toast.info("Favor informe todos os campos obrigatórios!");
    }

    let paymentDate = paymentPrevisionDate;

    try {
      setLoading(true);

      if (!paymentDate && requestSelect?.paymentCondition) {
        paymentDate = `${format(
          addDays(
            new Date(registerDate),
            requestSelect?.paymentCondition?.numeroDias
          ),
          "yyyy-MM-dd"
        )}`;
      }

      const body = new Invoices(
        "",
        `${number}`,
        new Date(registerDate),
        new Date(paymentDate),
        `${value
          ?.toString()
          ?.replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim()}`,
        `${netValue
          ?.toString()
          ?.replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim()}`,
        emissor,
        tomador,
        requestSelect?.id
      );

      const response = await invoicesConsumer.created(body);

      if (response.status !== 200 && response.status !== 204) throw response;

      setPage({ ...page, page: 0 });

      toast.success("Nota cadastrada com sucesso!");

      const responseRelation =
        await invoicesConsumer.createdRelationWithRequest({
          notaFiscalId: response.data.id,
          pedidoId: requestSelect?.id,
        });

      if (
        responseRelation.status !== 200 &&
        responseRelation.status !== 201 &&
        responseRelation.status !== 204
      ) {
        return toast.error("Erro ao relacionar nota com o pedido!");
      }

      body.id = response.data.id;
      setInvoiceSelect(body);
    } catch (e) {
      toast.error("Erro ao cadastrar nova nota!");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const body = new Invoices(
        `${invoiceSelect?.id}`,
        `${number}`,
        new Date(registerDate),
        new Date(paymentPrevisionDate),
        `${value
          ?.toString()
          ?.replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim()}`,
        `${netValue
          ?.toString()
          ?.replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim()}`,
        emissor,
        tomador,
        requestSelect?.id
      );

      const response = await invoicesConsumer.updated(body);

      if (response.status !== 200 && response.status !== 204) throw response;

      setPage({ ...page, page: 0 });

      setInvoiceSelect(body);
      toast.success("Nota alterada com sucesso!");
      handleSelect(null);
      setOpenDialog(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.errorMessage ?? "Erro ao alterar a nota!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (invoice: iInvoices) => {
    try {
      if (!invoice) return toast.warning("Selecione uma nota!");

      const idRelationFromInvoiceWithRequest = invoice.invoicesRequest?.find(
        (item) => item.pedidoId === requestSelect?.id
      );

      if (!idRelationFromInvoiceWithRequest) {
        return toast.warning(
          "Desculpe, não é possível deletar a nota, não foi encontrado relação da nota com o pedido!"
        );
      }

      setLoading(true);

      const responseRelation =
        await invoicesConsumer.deletedRelationWithRequest(
          idRelationFromInvoiceWithRequest.id
        );

      if (
        responseRelation.status !== 200 &&
        responseRelation.status !== 201 &&
        responseRelation.status !== 204
      )
        throw responseRelation;

      const response = await invoicesConsumer.deleted(invoice?.id);

      if (response.status !== 200 && response.status !== 204) throw response;

      setPage({ ...page, page: 0 });

      getInvoices(requestSelect!);
      setInvoiceSelect(null);
      setDisableButtons(true);

      toast.success("Nota deletada com sucesso!");
    } catch {
      toast.error("Erro ao deletar a nota!");
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (invoice: iInvoices | null) => {
    setInvoiceSelect(invoice ?? null);
    setNumber(invoice?.number ?? "");
    setNetValue(invoice?.netValue ?? "");
    setRegisterDate(
      invoice?.emissionDate?.toString()?.replace(/T.*/, "") ?? ""
    );
    setPaymentPrevisionDate(
      invoice?.paymentPrevisionDate?.toString()?.replace(/T.*/, "") ?? ""
    );
    setEmissor(invoice?.emissor ?? "");
    setEmissorName(invoice?.companyNameEmissor ?? "");
    setTomador(invoice?.tomador ?? "");
    setTomadorName(invoice?.companyNameTomador ?? "");

    if (invoice?.items && invoice?.items?.length !== 0) {
      setValue(
        invoice?.items
          ?.map((item: any) => item.quantidade * item.valor)
          ?.reduce(
            (accumulator: any, curretValue: any) => (accumulator += curretValue)
          )
          ?.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }) ?? "0,00"
      );
    } else {
      setValue("");
    }

    if (invoice?.emissor) {
      getProducts(
        invoice.emissor,
        pageProductCompany.page,
        pageProductCompany.rowsPerPage,
        false
      );
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getInvoices,
      loading,
      setLoading,
      invoices,
      handleChangePage,
      handleChangeRowsPerPage,
      number,
      setNumber,
      value,
      setValue,
      registerDate,
      setRegisterDate,
      endDate,
      setEndDate,
      netValue,
      setNetValue,
      invoiceSelect,
      setInvoices,
      setInvoiceSelect,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      handleSelect,
      emissor,
      setEmissor,
      tomador,
      setTomador,
      emissorName,
      setEmissorName,
      tomadorName,
      setTomadorName,
      paymentPrevisionDate,
      setPaymentPrevisionDate,
      orderBy,
      setOrderBy,
    };
  }, [
    page,
    loading,
    invoices,
    number,
    value,
    registerDate,
    endDate,
    netValue,
    invoiceSelect,
    emissor,
    tomador,
    emissorName,
    tomadorName,
    paymentPrevisionDate,
    orderBy,
  ]);

  return (
    <InvoicesContext.Provider value={contextValue}>
      {children}
    </InvoicesContext.Provider>
  );
}

export default InvoicesContext;
