/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useShoppingProcess from "src/hooks/useShoppingProcess";
import { iPage } from "src/interfaces/layout";
import requestConsumer from "src/services/request";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const RoutineContext = createContext<any>({} as any);

export function RoutineProvider({ children }: { children: any }) {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { getShoppingProcess } = useShoppingProcess();

  const [loading, setLoading] = useState<boolean>(false);

  const [requestSearch, setRequestSearch] = useState<string>("");

  const [dataRequests, setDataRequests] = useState<any[]>([]);

  const [shoppingProccessSelected, setShoppingProccessSelected] =
    useState<any>(null);

  const [requestSelected, setRequestSelected] = useState<any[]>([]);

  const [page, setPage] = useState<iPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const getRequestsWithoutShoppingProccess = async () => {
    setLoading(true);

    try {
      const response = await requestConsumer.get(
        page,
        "",
        "",
        "",
        requestSearch,
        "",
        "",
        "",
        "",
        "",
        "",
        true
      );

      if (response.status !== 200) throw response;

      if (page.total === 0 && response.data.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
        });
      }

      setDataRequests(response.data.items);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLink = async () => {
    setLoading(true);

    try {
      const body = requestSelected?.map((item) => {
        return {
          id: item.id,
          processoCompraId: shoppingProccessSelected.id,
        };
      });

      const response = await requestConsumer.requestListLink(body);

      if (response.status !== 200) throw response;

      toast.success(translations[param]["success_general"]);

      setShoppingProccessSelected(null);
      setRequestSelected([]);

      getRequestsWithoutShoppingProccess();
      getShoppingProcess();
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ?? translations[param]["error_general"]
      );
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      loading,
      setLoading,
      handleChangePage,
      handleChangeRowsPerPage,
      dataRequests,
      setDataRequests,
      getRequestsWithoutShoppingProccess,
      requestSearch,
      setRequestSearch,
      shoppingProccessSelected,
      setShoppingProccessSelected,
      requestSelected,
      setRequestSelected,
      handleLink,
    };
  }, [
    page,
    loading,
    dataRequests,
    requestSearch,
    shoppingProccessSelected,
    requestSelected,
  ]);

  return (
    <RoutineContext.Provider value={contextValue}>
      {children}
    </RoutineContext.Provider>
  );
}

export default RoutineContext;
