import { iCompanyContact } from "src/interfaces/companyContact";

export default class CompanyContact implements iCompanyContact {
  id?: string | null;
  name: string;
  email: string;
  phone: string;
  office: string;
  representative: boolean;
  cpf: string;
  companyId?: string | null;
  areaId?: string | null;
  main?: boolean;
  cell?: string;

  constructor(
    id: string | null,
    name: string,
    email: string,
    phone: string,
    office: string,
    representative: boolean,
    cpf: string,
    companyId?: string | null,
    areaId?: string | null,
    main?: boolean,
    cell?: string
  ) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
    this.office = office;
    this.representative = representative;
    this.cpf = cpf;
    this.companyId = companyId;
    this.areaId = areaId;
    this.main = main;
    this.cell = cell;
  }

  public static adapterToClass(companyContact: any) {
    return new CompanyContact(
      companyContact.id,
      companyContact.nome,
      companyContact.email,
      companyContact.telefone,
      companyContact.cargo,
      companyContact.representante,
      companyContact.cpf,
      companyContact.empresaId,
      companyContact.areaId,
      companyContact.principal,
      companyContact.celular
    );
  }

  public static adapterToJson(company: any) {
    return JSON.parse(company);
  }

  public static adapterToBody(companyContact: iCompanyContact) {
    const data: any = {
      nome: companyContact.name,
      email: companyContact.email,
      telefone: companyContact.phone,
      cargo: companyContact.office,
      representante: companyContact.representative,
      empresaId: companyContact.companyId,
      principal: companyContact.main,
      celular: companyContact.cell ?? null,
    };

    if (companyContact?.id) {
      data["id"] = companyContact?.id;
    }

    if (companyContact?.cpf) {
      data["cpf"] = companyContact.cpf;
    }

    if (companyContact?.areaId && companyContact?.areaId !== "select") {
      data["areaId"] = companyContact?.areaId;
    }

    return data;
  }
}
