import { localStorageStrings } from "src/constants/localStorageStings";
import { iShoppingProcess } from "src/interfaces/shoppingProcess";

export default class ShoppingProcess implements iShoppingProcess {
  id?: string | null;
  number: string;
  description: string;
  registerDate: any;
  endDate: string;
  rateValue: string;
  projectId: string;
  monthNumber: string;
  buyerAdministratorId: string;
  buyerAdministrator?: string;
  contactCompanyId: string;
  processBuyerTypeId: string;
  processBuyerTypeDescription?: string;
  contactCompanyName?: string;
  status?: string;
  totalValue?: number;
  statusId?: string;
  estimatedValue?: string;
  dataInicioLevantamento?: string;
  dataFimLevantamento?: string;
  cartaConvite?: string;
  dataInicioVigencia?: string;
  dataFimVigencia?: string;
  dataInicioProcesso?: string;

  constructor(
    id: string | null,
    number: string,
    description: string,
    registerDate: any,
    endDate: string,
    rateValue: string,
    projectId: string,
    monthNumber: string,
    buyerAdministratorId: string,
    buyerAdministrator: string,
    contactCompanyId: string,
    processBuyerTypeId: string,
    processBuyerTypeDescription: string,
    contactCompanyName: string,
    status: string,
    totalValue?: number,
    statusId?: string,
    estimatedValue?: string,
    dataInicioLevantamento?: string,
    dataFimLevantamento?: string,
    cartaConvite?: string,
    dataInicioVigencia?: string,
    dataFimVigencia?: string,
    dataInicioProcesso?: string
  ) {
    this.id = id;
    this.number = number;
    this.description = description;
    this.registerDate = registerDate;
    this.endDate = endDate;
    this.rateValue = rateValue;
    this.projectId = projectId;
    this.monthNumber = monthNumber;
    this.buyerAdministratorId = buyerAdministratorId;
    this.buyerAdministrator = buyerAdministrator;
    this.contactCompanyId = contactCompanyId;
    this.processBuyerTypeId = processBuyerTypeId;
    this.processBuyerTypeDescription = processBuyerTypeDescription;
    this.contactCompanyName = contactCompanyName;
    this.status = status;
    this.totalValue = totalValue;
    this.statusId = statusId;
    this.estimatedValue = estimatedValue;
    this.dataInicioLevantamento = dataInicioLevantamento;
    this.dataFimLevantamento = dataFimLevantamento;
    this.cartaConvite = cartaConvite;
    this.dataInicioVigencia = dataInicioVigencia;
    this.dataFimVigencia = dataFimVigencia;
    this.dataInicioProcesso = dataInicioProcesso;
  }

  public static adapterToClass(shoppingProcess: any) {
    return new ShoppingProcess(
      shoppingProcess.id,
      shoppingProcess.numero,
      shoppingProcess.descricao,
      shoppingProcess.dataInicioPC,
      shoppingProcess.dataEncerramentoPC,
      shoppingProcess.valorTaxa,
      shoppingProcess.projetoId,
      shoppingProcess.numeroMeses,
      shoppingProcess.compradorAdministradorId,
      shoppingProcess.compradorAdministrador,
      shoppingProcess.responsavelAdministradorId,
      shoppingProcess.processoCompraTipoId,
      shoppingProcess.processoCompraTipo,
      shoppingProcess.responsavelAdministrador,
      shoppingProcess.processoCompraStatus,
      shoppingProcess.valorTotal,
      shoppingProcess.processoCompraStatusId,
      shoppingProcess.valorEstimado,
      shoppingProcess.dataInicioLevantamento,
      shoppingProcess.dataFimLevantamento,
      shoppingProcess.cartaConvite,
      shoppingProcess.dataInicioVigencia,
      shoppingProcess.dataFimVigencia,
      shoppingProcess.dataInicioPC
    );
  }

  public static adapterToJson(shoppingProcess: any) {
    return JSON.parse(shoppingProcess);
  }

  public static adapterToBody(shoppingProcess: iShoppingProcess) {
    const userLogged = localStorage.getItem(localStorageStrings.userLogged);
    const userJson = JSON.parse(`${userLogged}`);

    const data: any = {
      numero: shoppingProcess.number,
      descricao: shoppingProcess.description,
      dataCadastro: shoppingProcess.registerDate,
      numeroMeses: shoppingProcess.monthNumber,
      compradorAdministrador: shoppingProcess.buyerAdministratorId,
      processoCompraTipoId: shoppingProcess.processBuyerTypeId,
      responsavelAdministrador: shoppingProcess.contactCompanyId,
      dataInicioPC: shoppingProcess?.dataInicioProcesso,
      processoCompraTipo: {
        id: shoppingProcess.processBuyerTypeId,
      },
      usuarioSistemaResponsavel: userJson.login,
    };

    if (shoppingProcess.id) {
      data["id"] = shoppingProcess.id;
    }

    if (shoppingProcess.endDate && shoppingProcess.endDate !== "") {
      data["dataEncerramentoPC"] = shoppingProcess.endDate;
    }

    if (shoppingProcess.monthNumber) {
      data["numeroMeses"] = shoppingProcess.monthNumber;
    }

    if (
      shoppingProcess.projectId &&
      shoppingProcess.projectId !== "" &&
      shoppingProcess.projectId !== "select"
    ) {
      data["projetoId"] = shoppingProcess.projectId;
    }

    if (shoppingProcess.rateValue && shoppingProcess.rateValue !== "select") {
      data["valorTaxa"] = shoppingProcess.rateValue;
    }

    if (shoppingProcess.estimatedValue) {
      data["valorEstimado"] = shoppingProcess.estimatedValue;
    }

    if (shoppingProcess.dataInicioLevantamento) {
      data["dataInicioLevantamento"] = shoppingProcess.dataInicioLevantamento;
    }

    if (shoppingProcess.dataFimLevantamento) {
      data["dataFimLevantamento"] = shoppingProcess.dataFimLevantamento;
    }

    if (shoppingProcess.cartaConvite) {
      data["cartaConvite"] = shoppingProcess.cartaConvite;
    }

    if (shoppingProcess.dataInicioVigencia) {
      data["dataInicioVigencia"] = shoppingProcess.dataInicioVigencia;
    }

    if (shoppingProcess.dataFimVigencia) {
      data["dataFimVigencia"] = shoppingProcess.dataFimVigencia;
    }

    return data;
  }
}
