/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import useCompanyContact from "src/hooks/useCompanyContact";
import useLayout from "src/hooks/useLayout";
import usePaymentCondition from "src/hooks/usePaymentCondition";
import useShoppingProcessPhases from "src/hooks/useShoppingProcessPhases";
import {
  iShoppingProcessPhasesItem,
  iShoppingProcessPhasesItemContextProps,
  iShoppingProcessPhasesItemPage,
} from "src/interfaces/shoppingProcessPhasesItem";
import ShoppingProcessPhasesItem from "src/models/ShoppingProcessPhasesItem";
import shoppingProcessConsumer from "src/services/shoppingProcess";
import ShoppingProcessPhasesItemConsumer from "src/services/shoppingProcessPhasesItem";

const ShoppingProcessPhasesItemContext =
  createContext<iShoppingProcessPhasesItemContextProps>(
    {} as iShoppingProcessPhasesItemContextProps
  );

export function ShoppingProcessPhasesItemProvider({
  children,
}: {
  children: any;
}) {
  const { setOpenDialogSecondary } = useLayout();
  const { getCompanysContacts } = useCompanyContact();
  const { getPaymentConditions } = usePaymentCondition();
  const { setStatusProgress } = useShoppingProcessPhases();

  const [loading, setLoading] = useState<boolean>(false);
  const [shoppingProcessPhasesItems, setShoppingProcessPhasesItems] = useState<
    Array<iShoppingProcessPhasesItem>
  >([]);
  const [shoppingProcesPhasesItemSelect, setShoppingProcesPhasesItemSelect] =
    useState<iShoppingProcessPhasesItem | null>(null);
  const [page, setPage] = useState<iShoppingProcessPhasesItemPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [quantity, setQuantity] = useState<number>(0);
  const [sumValueTotalItems, setSumValueTotalItems] = useState<number>(0);
  const [baseValue, setBaseValue] = useState<string>("");
  const [targetValue, setTargetValue] = useState<string>("");
  const [unitValue, setUnitValue] = useState<string>("");
  const [totalValue, setTotalValue] = useState<string>("");
  const [buyerId, setBuyerId] = useState<string>("");
  const [buyerValue, setBuyerValue] = useState<any>(null);
  const [supplierId, setSupplierId] = useState<string>("");
  const [supplierValue, setSupplierValue] = useState<any>(null);
  const [unitId, setUnitId] = useState<string>("select");
  const [proccessBuyerPhaseId, setProccessBuyerPhaseId] = useState<string>("");
  const [paymentConditionId, setPaymentConditionId] = useState<string>("");
  const [paymentConditionValue, setPaymentConditionValue] = useState<any>(null);
  const [shoppingProccessItemId, setShoppingProccessItemId] =
    useState<string>("");
  const [buyerResponsibleId, setBuyerResponsibleId] = useState<string>("");
  const [buyerResponsibleValue, setBuyerResponsibleValue] = useState<any>(null);
  const [supplierResponsibleId, setSupplierResponsibleId] =
    useState<string>("");
  const [supplierResponsibleValue, setSupplierResponsibleValue] =
    useState<any>(null);
  const [buyerResponsible, setBuyerResponsible] = useState<string>("");
  const [supplierResponsible, setSupplierResponsible] = useState<string>("");
  const [ratesPCI, setRatesPCI] = useState<any[]>([]);
  const [orderBy, setOrderBy] = useState<any>({ number: 2, order: true });

  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [buyer, setBuyer] = useState<string>("");
  const [supplier, setSupplier] = useState<string>("");
  const [paymentCondition, setPaymentCondition] = useState<string>("");
  const [unit, setUnit] = useState<string>("");

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getShoppingProcessPhasesItems = async (
    shoppingProccessPhaseId?: string
  ) => {
    setLoading(true);
    try {
      const response = await ShoppingProcessPhasesItemConsumer.get(
        page,
        shoppingProccessPhaseId ?? "",
        orderBy
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ShoppingProcessPhasesItem.adapterToClass(item)
      );

      if (page.total === 0 && response?.data?.totalItems !== 0) {
        setPage({
          ...page,
          total: response.data.totalItems,
          change: false,
        });
      }

      setShoppingProcessPhasesItems(data);

      return response.data;
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar os itens cadastrados!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelected = (
    shoppingProcessPhasesItem: iShoppingProcessPhasesItem | null
  ) => {
    setShoppingProcesPhasesItemSelect(shoppingProcessPhasesItem ?? null);
    setQuantity(shoppingProcessPhasesItem?.quantity ?? 0);

    if (shoppingProcessPhasesItem?.baseValue) {
      setBaseValue(
        parseFloat(shoppingProcessPhasesItem!.baseValue).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        )
      );
    } else {
      setBaseValue(
        parseFloat("0").toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }

    if (shoppingProcessPhasesItem?.targetValue) {
      setTargetValue(
        parseFloat(shoppingProcessPhasesItem!.targetValue).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ) ?? ""
      );
    } else {
      setTargetValue(
        parseFloat("0").toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }

    if (shoppingProcessPhasesItem?.unitValue) {
      setUnitValue(
        parseFloat(shoppingProcessPhasesItem!.unitValue).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        ) ?? ""
      );
    } else {
      setUnitValue(
        parseFloat("0").toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
      );
    }

    if (shoppingProcessPhasesItem?.buyerId) {
      const data = {
        id: shoppingProcessPhasesItem?.buyerId,
        label: shoppingProcessPhasesItem?.buyer,
      };

      setBuyerValue(data);
      getCompanysContacts(shoppingProcessPhasesItem?.buyerId, data);
      getPaymentConditions(data);
    } else {
      setBuyerValue(null);
    }

    if (shoppingProcessPhasesItem?.buyerResponsibleId) {
      setBuyerResponsibleValue({
        id: shoppingProcessPhasesItem?.buyerResponsibleId,
        label: shoppingProcessPhasesItem?.buyerResponsible,
      });
    } else {
      setBuyerResponsibleValue(null);
    }

    if (shoppingProcessPhasesItem?.supplierId) {
      const data = {
        id: shoppingProcessPhasesItem?.supplierId,
        label: shoppingProcessPhasesItem?.supplier,
      };

      setSupplierValue(data);
      getCompanysContacts(data.id, data);
    } else {
      setSupplierValue(null);
    }

    if (shoppingProcessPhasesItem?.supplierResponsibleId) {
      setSupplierResponsibleValue({
        id: shoppingProcessPhasesItem?.supplierResponsibleId,
        label: shoppingProcessPhasesItem?.supplierResponsible,
      });
    } else {
      setSupplierResponsibleValue(null);
    }

    if (shoppingProcessPhasesItem?.paymentConditionId) {
      setPaymentConditionValue({
        id: shoppingProcessPhasesItem?.paymentConditionId,
        label: shoppingProcessPhasesItem?.paymentCondition,
      });
    } else {
      setPaymentConditionValue(null);
    }

    setTotalValue(shoppingProcessPhasesItem?.totalValue ?? "");
    setBuyerResponsibleId(shoppingProcessPhasesItem?.buyerResponsibleId ?? "");
    setBuyerResponsible(shoppingProcessPhasesItem?.buyerResponsible ?? "");
    setBuyerId(shoppingProcessPhasesItem?.buyerId ?? "");
    setSupplierId(shoppingProcessPhasesItem?.supplierId ?? "");
    setSupplierResponsibleId(
      shoppingProcessPhasesItem?.supplierResponsibleId ?? ""
    );
    setSupplierResponsible(
      shoppingProcessPhasesItem?.supplierResponsible ?? ""
    );
    setBuyer(shoppingProcessPhasesItem?.buyer ?? "");
    setSupplier(shoppingProcessPhasesItem?.supplier ?? "");
    setUnitId(shoppingProcessPhasesItem?.unitId ?? "select");
    setProccessBuyerPhaseId(
      shoppingProcessPhasesItem?.proccessBuyerPhaseId ?? ""
    );
    setPaymentConditionId(shoppingProcessPhasesItem?.paymentConditionId ?? "");
    setPaymentCondition(shoppingProcessPhasesItem?.paymentCondition ?? "");
    setShoppingProccessItemId(
      shoppingProcessPhasesItem?.shoppingProccessItemId ?? ""
    );
    setDescription(shoppingProcessPhasesItem?.descriptionProduct ?? "");
    setCode(shoppingProcessPhasesItem?.codeProduct ?? "");
  };

  const handleNewSalve = async () => {
    try {
      setLoading(true);

      const totalValue =
        parseFloat(
          unitValue
            .replace("R$", "")
            .replaceAll(".", "")
            .replace(",", ".")
            .trim()
        ) * quantity;

      const body = new ShoppingProcessPhasesItem(
        "",
        quantity,
        baseValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        targetValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        unitValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        totalValue.toFixed(2),
        buyerId,
        supplierId,
        unitId,
        JSON.parse(localStorage.getItem("shoppingProcessPhases") ?? "")?.id,
        paymentConditionId,
        shoppingProccessItemId,
        buyerResponsibleId,
        supplierResponsibleId,
        buyer,
        supplier,
        paymentCondition,
        unit,
        code,
        description,
        buyerResponsible,
        supplierResponsible
      );

      const response = await ShoppingProcessPhasesItemConsumer.created(body);
      await getStatusInProgress(localStorage.getItem("shoppingProcessPhases"));

      if (response.status !== 200 && response.status !== 201) throw response;

      body.id = response.data?.id;
      setShoppingProcesPhasesItemSelect(body);
      setShoppingProcessPhasesItems([body, ...shoppingProcessPhasesItems]);
      handleSelected(null);

      const sumTotalRequest =
        sumValueTotalItems +
        quantity *
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          );

      setSumValueTotalItems(sumTotalRequest);
      setOpenDialogSecondary(false);
      toast.success("Item cadastrado com sucesso!");
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar o item!");
    } finally {
      setLoading(false);
    }
  };

  const getStatusInProgress = async (shoppingProcessPhases: any) => {
    const statusProgressData = await shoppingProcessConsumer.getStatusPC({
      shoppingProccessPhaseId: `${shoppingProcessPhases?.id}`,
    });

    if (statusProgressData?.status !== 20) setStatusProgress([]);

    setStatusProgress(statusProgressData.data.items);
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);

      const totalValue =
        parseFloat(
          unitValue
            .replace("R$", "")
            .replaceAll(".", "")
            .replace(",", ".")
            .trim()
        ) * quantity;

      const body = new ShoppingProcessPhasesItem(
        shoppingProcesPhasesItemSelect?.id ?? "",
        quantity,
        baseValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        targetValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        unitValue
          .replace("R$", "")
          .replaceAll(".", "")
          .replace(",", ".")
          .trim(),
        totalValue.toFixed(2),
        buyerId,
        supplierId,
        unitId,
        proccessBuyerPhaseId,
        paymentConditionId,
        shoppingProccessItemId,
        buyerResponsibleId,
        supplierResponsibleId,
        buyer,
        supplier,
        paymentCondition,
        unit,
        code,
        description,
        buyerResponsible,
        supplierResponsible
      );

      const response = await ShoppingProcessPhasesItemConsumer.updated(body);
      await getStatusInProgress(localStorage.getItem("shoppingProcessPhases"));

      if (response.status !== 200 && response.status !== 201) throw response;

      const newItems = shoppingProcessPhasesItems.filter(
        (e) => e.id !== shoppingProcesPhasesItemSelect?.id
      );

      body.id = shoppingProcesPhasesItemSelect?.id;
      setShoppingProcessPhasesItems([body, ...newItems]);
      setShoppingProcesPhasesItemSelect(body);
      handleSelected(null);

      let totalRequest =
        sumValueTotalItems -
        quantity *
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          );

      totalRequest = totalRequest <= 0 ? 0 : totalRequest;
      const sumTotalRequest =
        totalRequest +
        quantity *
          parseFloat(
            unitValue
              .replace("R$", "")
              .replaceAll(".", "")
              .replace(",", ".")
              .trim()
          );

      setSumValueTotalItems(sumTotalRequest);
      setOpenDialogSecondary(false);
      toast.success("Item alterado com sucesso!");
    } catch {
      toast.error("Ops... tivemos um problema ao alterar o item!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (
    shoppingProcessPhasesItem: iShoppingProcessPhasesItem
  ) => {
    try {
      setLoading(true);

      const response = await ShoppingProcessPhasesItemConsumer.deleted(
        `${shoppingProcessPhasesItem?.id}`
      );
      await getStatusInProgress(localStorage.getItem("shoppingProcessPhases"));

      if (response.status !== 200) throw response;

      const newItems = shoppingProcessPhasesItems.filter(
        (e) => e.id !== shoppingProcessPhasesItem?.id
      );

      setShoppingProcessPhasesItems([...newItems]);

      if (shoppingProcessPhasesItem.totalValue) {
        const sumTotalRequest =
          parseFloat(shoppingProcessPhasesItem.totalValue ?? "0") -
          sumValueTotalItems;

        setSumValueTotalItems(sumTotalRequest);
      } else {
        setSumValueTotalItems(0);
      }

      setPage({
        ...page,
        page: 0,
      });

      toast.success("Item deletado com sucesso!");
    } catch {
      toast.error("Erro ao deletar o item!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getShoppingProcessPhasesItems,
      loading,
      setLoading,
      shoppingProcessPhasesItems,
      setShoppingProcessPhasesItems,
      handleChangePage,
      handleChangeRowsPerPage,
      shoppingProcesPhasesItemSelect,
      setShoppingProcesPhasesItemSelect,
      handleSelected,
      quantity,
      setQuantity,
      baseValue,
      setBaseValue,
      targetValue,
      setTargetValue,
      unitValue,
      setUnitValue,
      totalValue,
      setTotalValue,
      buyerId,
      setBuyerId,
      supplierId,
      setSupplierId,
      unitId,
      setUnitId,
      proccessBuyerPhaseId,
      setProccessBuyerPhaseId,
      paymentConditionId,
      setPaymentConditionId,
      shoppingProccessItemId,
      setShoppingProccessItemId,
      handleNewSalve,
      handleUpdate,
      handleDelete,
      code,
      setCode,
      description,
      setDescription,
      buyer,
      setBuyer,
      paymentCondition,
      setPaymentCondition,
      unit,
      setUnit,
      supplier,
      setSupplier,
      buyerResponsibleId,
      setBuyerResponsibleId,
      supplierResponsibleId,
      setSupplierResponsibleId,
      buyerResponsible,
      setBuyerResponsible,
      supplierResponsible,
      setSupplierResponsible,
      ratesPCI,
      setRatesPCI,
      buyerValue,
      setBuyerValue,
      buyerResponsibleValue,
      setBuyerResponsibleValue,
      supplierValue,
      setSupplierValue,
      supplierResponsibleValue,
      setSupplierResponsibleValue,
      paymentConditionValue,
      setPaymentConditionValue,
      sumValueTotalItems,
      setSumValueTotalItems,
      orderBy,
      setOrderBy,
    };
  }, [
    page,
    loading,
    shoppingProcessPhasesItems,
    shoppingProcesPhasesItemSelect,
    quantity,
    baseValue,
    targetValue,
    unitValue,
    totalValue,
    buyerId,
    supplierId,
    unitId,
    proccessBuyerPhaseId,
    paymentConditionId,
    shoppingProccessItemId,
    code,
    description,
    buyer,
    paymentCondition,
    unit,
    supplier,
    buyerResponsibleId,
    supplierResponsibleId,
    buyerResponsible,
    supplierResponsible,
    ratesPCI,
    buyerValue,
    buyerResponsibleValue,
    supplierValue,
    supplierResponsibleValue,
    paymentConditionValue,
    sumValueTotalItems,
    orderBy,
  ]);

  return (
    <ShoppingProcessPhasesItemContext.Provider value={contextValue}>
      {children}
    </ShoppingProcessPhasesItemContext.Provider>
  );
}

export default ShoppingProcessPhasesItemContext;
