/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from "react";
import {
  iCompanyContact,
  iCompanyContactContextProps,
  iCompanyContactPage,
} from "src/interfaces/companyContact";
import CompanyContact from "src/models/CompanyContact";
import companyContactConsumer from "src/services/companyContact";
import { toast } from "react-toastify";
import Login from "src/models/Login";
import areaConsumer from "src/services/area";

const CompanyContactContext = createContext<iCompanyContactContextProps>(
  {} as iCompanyContactContextProps
);

export function CompanyContactProvider({ children }: { children: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [companyContactSelected, setCompanyContactSelected] =
    useState<iCompanyContact | null>(null);
  const [areas, setAreas] = useState<any[]>([]);
  const [companyContacts, setCompanyContacts] = useState<
    Array<iCompanyContact>
  >([]);
  const [validationErrorEmail, setValidationErrorEmail] =
    useState<boolean>(false);
  const [page, setPage] = useState<iCompanyContactPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
    change: true,
  });

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [cell, setCell] = useState<string>("");
  const [cpf, setCpf] = useState<string>("");
  const [office, setOffice] = useState<string>("");
  const [representative, setRepresentative] = useState<boolean>(false);
  const [main, setMain] = useState<boolean>(false);
  const [area, setArea] = useState<string>("select");

  const [companysContactToSearch, setCompanysContactToSearch] = useState<
    Array<any>
  >([]);
  const [companysContactToSearchSupplier, setCompanysContactToSearchSupplier] =
    useState<Array<any>>([]);
  const [companysContactToSearchBuyer, setCompanysContactToSearchBuyer] =
    useState<Array<any>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage, change: true });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
      change: true,
    });
  };

  const getCompanysContacts = async (companyId: string, company?: any) => {
    setLoading(true);

    try {
      const response = await companyContactConsumer.get(page, companyId);

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        CompanyContact.adapterToClass(item)
      );

      setPage({
        ...page,
        total: response.data.totalItems,
        change: false,
      });

      setCompanysContactToSearch(
        response.data.items.map((item: any) => {
          return {
            ...item,
            id: item.id,
            label: item.nome,
          };
        })
      );

      if (companysContactToSearchBuyer.length === 0 && company?.buyer) {
        setCompanysContactToSearchBuyer(
          response.data.items.map((item: any) => {
            return {
              id: item.id,
              label: item.nome,
            };
          })
        );
      }

      if (company?.supplier) {
        setCompanysContactToSearchSupplier(
          response.data.items.map((item: any) => {
            return {
              id: item.id,
              label: item.nome,
            };
          })
        );
      }

      setCompanyContacts(data);
    } catch (e) {
      toast.error(
        "Ops... identificamos um erro ao buscar as redes cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (data: iCompanyContact | null) => {
    setCompanyContactSelected(data);

    setName(data?.name ?? "");
    setCpf(data?.cpf ?? "");
    setEmail(data?.email ?? "");
    setPhone(data?.phone ?? "");
    setOffice(data?.office ?? "");
    setRepresentative(data?.representative ?? false);
    setArea(data?.areaId ?? "select");
    setMain(data?.main ?? false);
    setCell(data?.cell ?? "");
  };

  const handleSalve = async (companyId: string) => {
    if (!area || area === "select") {
      return toast.warning("O campo área é obrigatório!");
    }

    try {
      setValidationErrorEmail(false);

      setLoading(true);

      const contact = new CompanyContact(
        null,
        name,
        email,
        phone,
        office,
        representative,
        cpf,
        companyId,
        area,
        main,
        cell
      );

      const response = await companyContactConsumer.created(contact);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Novo contato cadastrado com sucesso!");
      handleSelect(null);
      getCompanysContacts(companyId);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao cadastrar o novo contato!");
    } finally {
      setLoading(false);
    }
  };

  const handleSalveApi = async (companyId: string, bodyArrayData: any) => {
    const phones = bodyArrayData.filter((item: any) => item.ddd && item);
    const contacts = bodyArrayData.filter((item: any) => item.nome && item);

    let latsPhone = "";

    try {
      setValidationErrorEmail(false);

      setLoading(true);

      return Promise.all(
        contacts.map(async (item: any, index: number) => {
          if (index === 0 && phones.length !== 0) {
            latsPhone = `${phones[0].ddd}${phones[0].numero}`;
          } else if (phones[index] !== undefined) {
            latsPhone = `${phones[index].ddd}${phones[index].numero}`;
          }

          const contact = new CompanyContact(
            null,
            item.nome,
            email,
            latsPhone,
            office,
            representative,
            cpf,
            companyId,
            "",
            main,
            cell
          );

          const response = await companyContactConsumer.created(contact);

          if (response.status !== 200 && response.status !== 201)
            throw response;
        })
      )
        .then(() => {
          toast.success("Novo contato cadastrado com sucesso!");
          handleSelect(null);
          getCompanysContacts(companyId);
        })
        .catch((e) =>
          toast.error(
            e?.response?.data?.message ??
              "Ops... tivemos um problema ao cadastrar o novo contato!"
          )
        );
    } catch (e: any) {
      toast.error(
        e?.data?.message ??
          "Ops... tivemos um problema ao cadastrar o novo contato!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (companyId: string) => {
    if (!area || area === "select") {
      return toast.warning("O campo área é obrigatório!");
    }

    try {
      setValidationErrorEmail(false);

      if (!Login.verifyEmail(email)) return setValidationErrorEmail(true);

      setLoading(true);

      const contact = new CompanyContact(
        companyContactSelected?.id ?? "",
        name,
        email,
        phone,
        office,
        representative,
        cpf,
        companyId,
        area,
        main,
        cell
      );

      const response = await companyContactConsumer.updated(contact);

      if (response.status !== 200 && response.status !== 201) throw response;

      toast.success("Contato alterado com sucesso!");
      handleSelect(null);
      getCompanysContacts(companyId);
    } catch (e) {
      toast.error("Ops... tivemos um problema ao alterar o contato!");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (contact: iCompanyContact) => {
    try {
      setLoading(true);

      const response = await companyContactConsumer.deleted(`${contact?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Contato deletada com sucesso!");

      handleSelect(null);
      getCompanysContacts(contact?.companyId ?? "");
    } catch {
      toast.error("Erro ao deletar o contato!");
    } finally {
      setLoading(false);
    }
  };

  const getAreas = async () => {
    setLoading(false);

    try {
      const response = await areaConsumer.get(page);

      if (response.status !== 200) throw response;

      setAreas(response.data.items);
    } catch {
      toast.warning("Erro ao buscar as áreas cadastradas");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      page,
      getCompanysContacts,
      loading,
      setLoading,
      companyContacts,
      handleChangePage,
      handleChangeRowsPerPage,
      companyContactSelected,
      setCompanyContactSelected,
      name,
      setName,
      email,
      setEmail,
      phone,
      setPhone,
      office,
      setOffice,
      representative,
      setRepresentative,
      companysContactToSearch,
      setCompanysContactToSearch,
      companysContactToSearchSupplier,
      setCompanysContactToSearchSupplier,
      companysContactToSearchBuyer,
      validationErrorEmail,
      setValidationErrorEmail,
      handleSelect,
      area,
      setArea,
      handleSalve,
      handleSalveApi,
      handleUpdate,
      handleDelete,
      areas,
      getAreas,
      main,
      setMain,
      cpf,
      setCpf,
      cell,
      setCell,
    };
  }, [
    page,
    loading,
    companyContacts,
    name,
    email,
    phone,
    office,
    representative,
    companyContactSelected,
    companysContactToSearch,
    companysContactToSearchSupplier,
    companysContactToSearchBuyer,
    area,
    areas,
    main,
    cpf,
    cell,
  ]);

  return (
    <CompanyContactContext.Provider value={contextValue}>
      {children}
    </CompanyContactContext.Provider>
  );
}

export default CompanyContactContext;
