/* eslint-disable react-hooks/exhaustive-deps */
import {
  iProductCategory,
  iProductCategoryContextProps,
  iProductCategoryPage,
} from "src/interfaces/products";
import { createContext, useMemo, useState } from "react";
import productCategoryConsumer from "src/services/productCategory";
import ProductCategory from "src/models/ProductCategory";
import useCompany from "src/hooks/useCompany";
import { toast } from "react-toastify";

const ProductCategoryContext = createContext<iProductCategoryContextProps>(
  {} as iProductCategoryContextProps
);

export function ProductCategoryProvider({ children }: { children: any }) {
  const { companySelected } = useCompany();

  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<iProductCategoryPage>({
    page: 0,
    rowsPerPage: 10,
    total: 0,
  });

  const [productCategorySelected, setProductCategorySelected] =
    useState<iProductCategory | null>(null);

  const [productCategorys, setProductCategorys] = useState<
    Array<iProductCategory>
  >([]);
  const [productCategorysToSearch, setProductCategorysToSearch] = useState<
    Array<any>
  >([]);

  const [code, setCode] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [descriptionSearch, setDescriptionSearch] = useState<string>("");
  const [fatherCategory, setFatherCategory] = useState<any>(null);
  const [productCategoryGeneral, setProductCategoryGeneral] =
    useState<any>(null);
  const [active, setActive] = useState<boolean>(false);
  const [productsToSearch, setProductsToSearch] = useState<Array<any>>([]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage({ ...page, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPage({
      ...page,
      page: 0,
      rowsPerPage: parseInt(event.target.value),
    });
  };

  const getCategoriesSearch = async (
    idCompany: string,
    pageNumber: number,
    rowsPerPage: number,
    productCategoryVinculateInCompany?: boolean
  ) => {
    try {
      const response = await productCategoryConsumer.get(
        idCompany,
        {
          ...page,
          rowsPerPage: rowsPerPage,
          page: pageNumber,
        },
        productCategoryVinculateInCompany ?? false,
        descriptionSearch
      );

      if (response.status !== 200) throw response;

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          page: pageNumber,
          rowsPerPage: rowsPerPage,
          total: response.data.totalItems,
        });
      }

      setProductsToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item.id,
            code: item.codigo,
            label: item.descricao,
          };
        })
      );
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar as categorias cadastradas!"
      );
    }
  };

  const getCategories = async (
    idCompany: string,
    pageNumber: number,
    rowsPerPage: number,
    productCategoryVinculateInCompany?: boolean
  ) => {
    try {
      setLoading(true);

      const response = await productCategoryConsumer.get(
        idCompany,
        {
          ...page,
          rowsPerPage: rowsPerPage,
          page: pageNumber,
        },
        productCategoryVinculateInCompany ?? false,
        descriptionSearch
      );

      if (response.status !== 200) throw response;

      const data = response.data.items.map((item: any) =>
        ProductCategory.adapterToClass(item)
      );

      if (page.total === 0 && response.data.totalItems > 0) {
        setPage({
          ...page,
          page: pageNumber,
          rowsPerPage: rowsPerPage,
          total: response.data.totalItems,
        });
      }

      setProductCategorys(data);
      setProductCategorysToSearch(
        response.data.items.map((item: any) => {
          return {
            id: item?.id ?? "",
            label: item?.descricao ?? "Não informado",
            code: item?.codigo ?? "",
          };
        })
      );
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... identificamos um erro ao buscar as categorias cadastradas!"
      );
    } finally {
      setLoading(false);
    }
  };

  const toFillUSerDataOnForm = (data: iProductCategory | null) => {
    setProductCategorySelected(data);
    setCode(data?.code ?? "");
    setDescription(data?.description ?? "");
    setActive(data?.active ?? false);
    setProductCategoryGeneral({
      id: data?.productCategoryGeneral ?? "",
      label: data?.categoriaProdutoPrincipal ?? "",
      code: data?.categoriaProdutoCodigo ?? "",
    });
    setFatherCategory({
      id: data?.categoryProductId ?? "",
      code: data?.categoriaProdutoEmpresaCode ?? "",
      label: data?.categoryProduct ?? "",
    });
  };

  const handleSalveNewProductCategory = async () => {
    try {
      setLoading(true);

      const productCategory = new ProductCategory(
        null,
        code,
        description,
        companySelected?.id,
        fatherCategory?.id,
        fatherCategory?.label,
        active,
        productCategoryGeneral?.id
      );

      const response = await productCategoryConsumer.created(productCategory);

      if (response.status !== 200 && response.status !== 201) throw response;

      productCategory.id = response.data.id;
      const newCategories = [productCategory, ...productCategorys];
      setProductCategorys(newCategories);
      toast.success("Novo categoria cadastrada com sucesso!");
      toFillUSerDataOnForm(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao cadastrar a nova categoria!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProductCategory = async () => {
    try {
      setLoading(true);

      const productCategory = new ProductCategory(
        productCategorySelected?.id as string,
        code,
        description,
        companySelected?.id,
        fatherCategory?.id,
        fatherCategory?.label,
        active,
        productCategoryGeneral?.id
      );

      const response = await productCategoryConsumer.updated(productCategory);

      if (response.status !== 200 && response.status !== 201) throw response;

      const categories = productCategorys.filter(
        (e) => e.id !== productCategory.id
      );

      const newCategories = [productCategory, ...categories];
      setProductCategorys(newCategories);
      toast.success("Categoria alterada com sucesso!");
      toFillUSerDataOnForm(null);
    } catch (e: any) {
      toast.error(
        e?.response?.data?.message ??
          "Ops... tivemos um problema ao alterar a categoria!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProductCategory = async (category: iProductCategory) => {
    try {
      setLoading(true);

      const response = await productCategoryConsumer.deleted(`${category?.id}`);

      if (response.status !== 200) throw response;

      toast.success("Categoria deletada com sucesso!");

      setPage({
        ...page,
        page: 0,
      });

      const categories = productCategorys.filter((e) => e.id !== category.id);

      const newCategories = [...categories];
      setProductCategorys(newCategories);
      toFillUSerDataOnForm(null);
    } catch (e: any) {
      toast.error(e?.response?.data?.message ?? "Erro ao deletar a categoria!");
    } finally {
      setLoading(false);
    }
  };

  const contextValue = useMemo(() => {
    return {
      productCategorys,
      setProductCategorys,
      productCategorysToSearch,
      setProductCategorysToSearch,
      productCategorySelected,
      setProductCategorySelected,
      getCategories,
      loading,
      page,
      code,
      setCode,
      description,
      setDescription,
      descriptionSearch,
      setDescriptionSearch,
      fatherCategory,
      setFatherCategory,
      active,
      setActive,
      handleChangePage,
      handleChangeRowsPerPage,
      handleSalveNewProductCategory,
      toFillUSerDataOnForm,
      handleUpdateProductCategory,
      handleDeleteProductCategory,
      productCategoryGeneral,
      setProductCategoryGeneral,
      productsToSearch,
      setProductsToSearch,
      getCategoriesSearch,
    };
  }, [
    productCategorys,
    productCategorysToSearch,
    productCategorySelected,
    code,
    description,
    descriptionSearch,
    fatherCategory,
    active,
    productCategoryGeneral,
    loading,
    page,
    productsToSearch,
  ]);

  return (
    <ProductCategoryContext.Provider value={contextValue}>
      {children}
    </ProductCategoryContext.Provider>
  );
}

export default ProductCategoryContext;
