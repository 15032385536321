/* eslint-disable react-hooks/exhaustive-deps */
import { iBreadcrumbs } from "src/interfaces/breadcrumbs";
import {
  ButtonDelete,
  ButtonInclude,
  ButtonSearch,
} from "src/components/ButtonsCommons";
import Layout from "src/components/Layout";
import useLayout from "src/hooks/useLayout";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { palette } from "src/theme";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Card,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { ItemCustom } from "src/components/ItemCustom";
import useInvoicing from "src/hooks/useInvoicing";
import ReactInputMask from "react-input-mask";
import { MaskStyle } from "src/styles/globalStyle";
import CircularLoading from "src/components/Loading";
import Empty from "src/components/Empty";
import TableComponent from "src/components/TableComponent";
import { iInvoicing } from "src/interfaces/invoicing";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";
import useTable from "src/hooks/useTable";

const Invoicing: React.FC = () => {
  const { disableButtons, setDisableButtons, width } = useLayout();
  const router = useNavigate();
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];

  const { setFieldOrder } = useTable();

  const breadcrumb: Array<iBreadcrumbs> = [
    {
      name: translations[param]["invoicing"],
    },
  ];

  const header = [
    {
      id: "",
      label: translations[param]["date"],
      isOrder: false,
      order: 1,
    },
    {
      id: "",
      label: translations[param]["supplier"],
      isOrder: false,
      order: 2,
    },
    {
      id: "",
      label: translations[param]["value"],
      isOrder: false,
      order: 3,
    },
    {
      id: "",
      label: translations[param]["number_nf"],
      isOrder: false,
      order: 4,
    },
    {
      id: "",
      label: translations[param]["date_nf"],
      isOrder: false,
      order: 5,
    },
    {
      id: "",
      label: `${translations[param]["value"]} ${translations[param]["paid_out"]}`,
      isOrder: false,
      order: 6,
    },
    {
      id: "",
      label: `${translations[param]["date"]} ${translations[param]["payment"]}.`,
      isOrder: false,
      order: 7,
    },
    {
      id: "",
      label: translations[param]["status"],
      isOrder: false,
      order: 8,
    },
  ];

  const {
    loading,
    getInvoicings,
    statusAvaliable,
    invoicings,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    invoicingSelect,
    handleSelect,
    handleDelete,
    orderBy,
    setOrderBy,
    clientSearch,
    setClientSearch,
    nfSearch,
    setNfSearch,
    dateFromSearch,
    setDateFromSearch,
    dateToSearch,
    setDateToSearch,
    statusSearch,
    setStatusSearch,
  } = useInvoicing();

  useEffect(() => {
    setDisableButtons(true);
    handleSelect(null);
    setFieldOrder({
      id: "",
      label: translations[param]["date"],
      isOrder: false,
      order: 1,
    });
  }, []);

  useEffect(() => {
    getInvoicings();
  }, [orderBy, page.total]);

  const buttons = [
    <ButtonInclude
      label={translations[param]["include"]}
      disabled={!disableButtons}
      onClick={() => router(`/${lang}/invocing/register`)}
    />,
    <ButtonDelete
      label={translations[param]["delete"]}
      disabled={disableButtons}
      onClick={() =>
        Swal.fire({
          title: `${translations[param]["attention"]}!`,
          text: `${translations[param]["the"]} ${translations[param]["invoicing"]} ${translations[param]["will_be_deleted_do_you_want_to_continue"]}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: palette.primary.main,
          cancelButtonColor: palette.error.main,
          confirmButtonText: `${translations[param]["yes"]}`,
          cancelButtonText: `${translations[param]["no"]}`,
        }).then((result) => {
          if (result.isConfirmed) handleDelete(invoicingSelect!);
        })
      }
    />,
  ];

  if (loading) {
    return (
      <Layout buttons={buttons} breadcrumbs={breadcrumb}>
        <CircularLoading />
      </Layout>
    );
  }

  return (
    <Layout buttons={buttons} breadcrumbs={breadcrumb}>
      <Card sx={{ padding: 2, width: "100%" }}>
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          display="flex"
          alignItems="center"
        >
          <Grid xs={12} sm={6} md={6}>
            <ItemCustom>
              {width >= 1400 ? (
                <FormLabel sx={{ minWidth: "14%" }}>
                  {translations[param]["client"]}:
                </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <MaskStyle>
                  <ReactInputMask
                    mask="99.999.999/9999-99"
                    name="cnpj"
                    placeholder={`CNPJ ${translations[param]["client"]}`}
                    value={clientSearch}
                    onChange={(e) => setClientSearch(e.target.value)}
                  />
                </MaskStyle>
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid xs={12} sm={6} md={6}>
            <ItemCustom>
              {width >= 1400 ? (
                <FormLabel sx={{ minWidth: "7%" }}>
                  {translations[param]["status"]}:{" "}
                </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <Select
                  labelId="show_select_search_company"
                  id="show_select_search_company"
                  value={statusSearch}
                  size="small"
                  onChange={(event: SelectChangeEvent) =>
                    setStatusSearch(event.target.value as string)
                  }
                >
                  <MenuItem value="select" disabled>
                    {width <= 1400
                      ? `${translations[param]["status"]} - ${translations[param]["select"]}`
                      : translations[param]["select"]}
                  </MenuItem>
                  <MenuItem value="">...</MenuItem>
                  {statusAvaliable?.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ItemCustom>
          </Grid>

          <Grid xs={12} sm={6} md={6}>
            <ItemCustom>
              {width >= 1400 ? (
                <FormLabel sx={{ minWidth: "14%" }}>NF: </FormLabel>
              ) : null}
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="nf"
                  name="nf"
                  label={translations[param]["number_nf"]}
                  variant="outlined"
                  value={nfSearch}
                  onChange={(e) => setNfSearch(e.target.value)}
                />
              </FormControl>
            </ItemCustom>
          </Grid>
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="end"
            xs={12}
            sm={6}
            md={6}
          >
            {width >= 1450 && (
              <FormLabel sx={{ marginX: "2%" }}>
                {translations[param]["from"]}:
              </FormLabel>
            )}
            <FormControl fullWidth>
              <MaskStyle>
                <input
                  type="date"
                  name="begin_date"
                  placeholder="dd/mm/yyyy"
                  value={dateFromSearch}
                  onChange={(e) => setDateFromSearch(e.target.value)}
                  min="1970-01-01"
                />
              </MaskStyle>
            </FormControl>

            {width >= 1450 && (
              <FormLabel sx={{ marginX: "1%" }}>
                {translations[param]["to"]}:
              </FormLabel>
            )}
            <FormControl fullWidth sx={{ paddingLeft: "1%" }}>
              <MaskStyle>
                <input
                  type="date"
                  name="end_date"
                  placeholder="dd/mm/yyyy"
                  value={dateToSearch}
                  onChange={(e) => setDateToSearch(e.target.value)}
                  min="1970-01-01"
                />
              </MaskStyle>
            </FormControl>
          </Grid>

          <Grid xs={12} display="flex" justifyContent="end">
            <ButtonSearch
              label={translations[param]["search"]}
              onClick={getInvoicings}
            />
          </Grid>
        </Grid>
      </Card>

      <Card sx={{ padding: 2, width: "100%", marginTop: 5 }}>
        {invoicings.length === 0 ? (
          <Empty />
        ) : (
          <TableComponent
            header={header}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            funtionToOrder={(item) =>
              item?.orderBackend &&
              setOrderBy({
                number: item?.orderBackend,
                order: item?.order === "asc" ? true : false,
              })
            }
          >
            {invoicings.map((data: iInvoicing) => (
              <TableRow
                hover
                key={data.id}
                selected={data.id === invoicingSelect?.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
              >
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.registerDate
                    ? format(new Date(data.registerDate), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data?.companyName}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.invoiceValue?.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  }) ?? "R$ 0,00"}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.numberNF}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.emissionDateNF
                    ? format(new Date(data.emissionDateNF), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {parseFloat(data.paymentValue ?? "0,00")?.toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.paymentDate
                    ? format(new Date(data.paymentDate), "dd/MM/yyyy")
                    : null}
                </TableCell>
                <TableCell
                  onClick={() => {
                    handleSelect(data);
                    router(`/${lang}/invocing/register`);
                  }}
                >
                  {data.status}
                </TableCell>
                <TableCell>
                  <Checkbox
                    color="primary"
                    checked={data.id === invoicingSelect?.id}
                    size="small"
                    onChange={(e) => {
                      if (!e.target.checked) {
                        handleSelect(null);
                        setDisableButtons(true);
                      }
                    }}
                    onClick={() => {
                      handleSelect(data);
                      setDisableButtons(false);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableComponent>
        )}
      </Card>
    </Layout>
  );
};

export default Invoicing;
