import styled from "styled-components";

export const DivFormsFieldLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .FormLabelSearch {
    width: 100%;
    text-align: right;
    margin-right: 4%;
  }

  @media only screen and (max-width: 600px) {
    .FormLabelSearch {
      margin-left: 0;
    }
  }
`;

export const MaskStyle = styled.div<{ isError?: boolean; width?: string }>`
  input {
    width: ${(props) => (props?.width ? `${props?.width}%` : "100%")};
    height: 40px;
    border: ${(props) =>
      props?.isError ? "1px solid #d32f2f" : "1px solid #aeb6bf"};
    border-radius: 16px;
    padding: 10px;
    font-family: "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
      '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
      '"Segoe UI Emoji"', '"Segoe UI Symbol"';
  }

  input:focus {
    outline: none !important;
    border-color: ${(props) => (props?.isError ? "#d32f2f" : "#719ece")};
    box-shadow: 0 0 2px #719ece;
  }
`;
