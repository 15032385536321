import { iAddress, iAddressPage } from "src/interfaces/address";
import Address from "src/models/Address";
import httpClientRegister from "src/http/register";

class addressConsumer {
  static get(idCompany: string, page: iAddressPage) {
    return httpClientRegister.get(
      `/Endereco?empresaId=${idCompany}&PageNumber=${page.page}&PageSize=${page.rowsPerPage}`
    );
  }

  static async created(address: iAddress) {
    const response = await httpClientRegister.post(
      `/Endereco`,
      Address.adapterToBody(address)
    );

    return response;
  }

  static updated(address: iAddress) {
    return httpClientRegister.put(`/Endereco`, Address.adapterToBody(address));
  }

  static deleted(id: string | null | undefined) {
    return httpClientRegister.delete(`/Endereco?id=${id}`);
  }
}

export default addressConsumer;
