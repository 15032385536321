/* eslint-disable react-hooks/exhaustive-deps */
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  TextField,
  Button,
  Divider,
  Box,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Collapse,
  Paper,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { theme } from "src/theme";
import { FormValues } from "src/contexts/demandCollectionFormContext";
import useDemandCollectionForm from "src/hooks/useDemandCollectionForm";
import { useEffect } from "react";
import CircularLoading from "src/components/Loading";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useLocation } from "react-router-dom";
import useShoppingProccessDemandClient from "src/hooks/useShoppingProcessDemandClient";

function DemandCollectionForm() {
  const {
    isMobile,
    handleDrawerToggle,
    selectedTab,
    handleTabChange,
    drawerOpen,
    isLargeScreen,
    formValues,
    handleInputChange,
    handleToggle,
    open,
    handleAddSection,
    indicacaoItensSections,
    handleRemoveSection,
  } = useDemandCollectionForm();

  const { getShoppingProcessDemandClient, loading, isError, demand } =
    useShoppingProccessDemandClient();

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processoCompraId = queryParams.get("ProcessoCompraId");
    const empresaId = queryParams.get("EmpresaId");

    // http://localhost:3000/pt/shopping/process/demand/collection/form?ProcessoCompraId=6fce33d8-f30e-4f95-b7d0-f65a254796a2&EmpresaId=62239caf-1213-43ab-a8a8-dca936039853
    if (processoCompraId && empresaId) {
      getShoppingProcessDemandClient(processoCompraId, empresaId);
    }
  }, []);

  if (isError) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 10 }}>
        <ErrorOutlineIcon sx={{ fontSize: 100, color: "primary.main" }} />
        <Typography variant="h3" sx={{ color: "primary.main", mt: 2 }}>
          400 - Ops, Não existe demanda cadastrada para este processo e esta
          empresa!
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary", mt: 2 }}>
          Ocorreu um erro inesperado. Estamos trabalhando para corrigir o
          problema.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => window.location.reload()}
        >
          Tentar Novamente
        </Button>
      </Container>
    );
  }

  const drawer = (
    <div>
      <List>
        <ListItem>
          <ListItemText primary="Demandas" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Logística" />
        </ListItem>
        <ListItem>
          <ListItemText primary="Indicação Fornecedores" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div>
      <AppBar position="static">
        <Toolbar style={{ backgroundColor: "#fff" }}>
          {isMobile ? (
            <IconButton
              edge="start"
              color="primary"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              TabIndicatorProps={{ style: { borderRadius: 4 } }}
            >
              <Tab
                label="Demandas"
                style={{
                  color:
                    selectedTab === 0 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 0 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 0 ? "4px 4px 0 0" : 0,
                }}
              />
              <Tab
                label="Logística"
                style={{
                  color:
                    selectedTab === 1 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 1 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 1 ? "4px 4px 0 0" : 0,
                }}
              />
              <Tab
                label="Indicação Fornecedores"
                style={{
                  color:
                    selectedTab === 2 ? "#fff" : theme.palette.primary.main,
                  backgroundColor:
                    selectedTab === 2 ? theme.palette.primary.main : "#fff",
                  borderRadius: selectedTab === 2 ? "4px 4px 0 0" : 0,
                }}
              />
            </Tabs>
          )}
        </Toolbar>
      </AppBar>

      <Divider
        style={{
          backgroundColor: theme.palette.primary.main,
        }}
      />

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        {drawer}
      </Drawer>

      {loading ? (
        <CircularLoading />
      ) : (
        <div style={{ padding: theme.spacing(2) }}>
          <Typography variant="h6" gutterBottom>
            Descrição Processo
          </Typography>

          <Grid container spacing={2}>
            {[
              { label: "Descrição do Processo", name: "descricao" },
              { label: "Número Processo", name: "numero" },
              { label: "CNPJ", name: "cnpj" },
              { label: "Empresa", name: "razaoSocial" },
              { label: "Comprador Responsável", name: "nomeComprador" },
              { label: "E-mail", name: "emailComprador" },
              { label: "Rede", name: "rede" },
              { label: "Telefone", name: "telefoneComprador" },
              { label: "Carta Convite", name: "cartaConvite" },
            ].map((field, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box display="flex" alignItems="center">
                  {isLargeScreen && (
                    <Grid md={4}>
                      <Typography
                        variant="body2"
                        style={{ marginRight: theme.spacing(1) }}
                      >
                        {field.label}
                      </Typography>
                    </Grid>
                  )}
                  <Grid xs={12} md={8}>
                    <TextField
                      placeholder={field.label}
                      fullWidth
                      size="small"
                      name={field.name}
                      disabled
                      value={demand && demand[field.name as keyof FormValues]}
                      onChange={(e) => handleInputChange(e, index, "")}
                      InputLabelProps={
                        isLargeScreen
                          ? {
                              shrink: true,
                              style: { position: "relative", marginBottom: 0 },
                            }
                          : undefined
                      }
                    />
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>

          <Divider
            style={{
              marginTop: theme.spacing(4),
              backgroundColor: theme.palette.primary.main,
            }}
          />

          <Box marginTop={theme.spacing(2)} marginBottom={theme.spacing(2)}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginTop={theme.spacing(2)}
              marginBottom={theme.spacing(2)}
            >
              <Typography variant="h6">Itens</Typography>
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                size="small"
              >
                Upload
              </Button>
            </Box>
            <Box>
              {!isMobile ? (
                <Table component={Paper}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Cód.</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Cód. Cliente</TableCell>
                      <TableCell>Descrição Cliente</TableCell>
                      <TableCell>Quantidade</TableCell>
                      <TableCell>Valor Últ. Compra (R$)</TableCell>
                      <TableCell>Valor Alvo (R$)</TableCell>
                      <TableCell>Não Possui Interesse</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(3)].map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>Lorem Ipsum</TableCell>
                        <TableCell>Lorem Ipsum</TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Cód. Cliente"
                            fullWidth
                            size="small"
                            name="codigoCliente"
                            value={formValues.codigoCliente}
                            onChange={(e) =>
                              handleInputChange(e, index, "item")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Descrição Cliente"
                            fullWidth
                            size="small"
                            name="descricaoCliente"
                            value={formValues.descricaoCliente}
                            onChange={(e) =>
                              handleInputChange(e, index, "item")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Quantidade"
                            fullWidth
                            size="small"
                            name="quantidade"
                            value={formValues.quantidade}
                            onChange={(e) =>
                              handleInputChange(e, index, "item")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Valor Últ. Compra (R$)"
                            fullWidth
                            size="small"
                            name="valorUltCompra"
                            value={formValues.valorUltCompra}
                            onChange={(e) =>
                              handleInputChange(e, index, "item")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            placeholder="Valor Alvo (R$)"
                            fullWidth
                            size="small"
                            name="valorAlvo"
                            value={formValues.valorAlvo}
                            onChange={(e) =>
                              handleInputChange(e, index, "item")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Box>
                  {[...Array(3)].map((_, index) => (
                    <Paper key={index} sx={{ marginBottom: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: 2,
                        }}
                      >
                        <Typography variant="subtitle1">
                          {`Cód.: Lorem Ipsum - Descrição: Lorem Ipsum`}
                        </Typography>
                        <IconButton onClick={() => handleToggle(index)}>
                          {open[index] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Box>
                      <Collapse in={open[index]}>
                        <Box sx={{ padding: 2 }}>
                          <Box>
                            <strong>Cód.:</strong> Lorem Ipsum
                          </Box>
                          <Box>
                            <strong>Descrição:</strong> Lorem Ipsum
                          </Box>
                          <Box>
                            <strong>Cód. Cliente:</strong>
                            <TextField
                              placeholder="Cód. Cliente"
                              fullWidth
                              size="small"
                              name="codigoCliente"
                              value={formValues.codigoCliente}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </Box>
                          <Box>
                            <strong>Descrição Cliente:</strong>
                            <TextField
                              placeholder="Descrição Cliente"
                              fullWidth
                              size="small"
                              name="descricaoCliente"
                              value={formValues.descricaoCliente}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </Box>
                          <Box>
                            <strong>Quantidade:</strong>
                            <TextField
                              placeholder="Quantidade"
                              fullWidth
                              size="small"
                              name="quantidade"
                              value={formValues.quantidade}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </Box>
                          <Box>
                            <strong>Valor Últ. Compra (R$):</strong>
                            <TextField
                              placeholder="Valor Últ. Compra (R$)"
                              fullWidth
                              size="small"
                              name="valorUltCompra"
                              value={formValues.valorUltCompra}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </Box>
                          <Box>
                            <strong>Valor Alvo (R$):</strong>
                            <TextField
                              placeholder="Valor Alvo (R$)"
                              fullWidth
                              size="small"
                              name="valorAlvo"
                              value={formValues.valorAlvo}
                              onChange={(e) =>
                                handleInputChange(e, index, "item")
                              }
                            />
                          </Box>
                          <Box>
                            <strong>Não Possui Interesse:</strong> <Checkbox />
                          </Box>
                        </Box>
                      </Collapse>
                    </Paper>
                  ))}
                </Box>
              )}
            </Box>
          </Box>

          <Divider
            style={{
              marginTop: theme.spacing(4),
              backgroundColor: theme.palette.primary.main,
            }}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop={theme.spacing(2)}
            marginBottom={theme.spacing(2)}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Indicação de Itens</Typography>
              <IconButton
                color="primary"
                onClick={() => handleAddSection("indicacaoItens")}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Box>

          {indicacaoItensSections.map((section: any, index: any) => (
            <Box key={index} marginBottom={theme.spacing(2)} display="flex">
              <Grid container spacing={2}>
                {[
                  { label: "Cód.", name: "codigo" },
                  { label: "Descrição", name: "descricao" },
                  { label: "Cód. Cliente", name: "codigoCliente" },
                  { label: "Descrição Cliente", name: "descricaoCliente" },
                  { label: "Quantidade", name: "quantidade" },
                  { label: "Valor Últ. Compra (R$)", name: "valorUltCompra" },
                  { label: "Valor Alvo (R$)", name: "valorAlvo" },
                ].map((field, idx) => (
                  <Grid item xs={12} sm={2} key={idx}>
                    <TextField
                      placeholder={field.label}
                      fullWidth
                      size="small"
                      name={field.name}
                      value={section[field.name as keyof FormValues]}
                      onChange={(e) =>
                        handleInputChange(e, index, "indicacaoItens")
                      }
                    />
                  </Grid>
                ))}
                <IconButton
                  onClick={() => handleRemoveSection(index, "indicacaoItens")}
                  style={{ marginTop: theme.spacing(1) }}
                >
                  <RemoveCircleOutlineIcon color="error" />
                </IconButton>
              </Grid>
            </Box>
          ))}

          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            style={{ marginTop: theme.spacing(4) }}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<CancelIcon />}
              >
                Declinar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}

export default DemandCollectionForm;
