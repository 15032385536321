import httpClientLogin from "src/http/login";
import { iLogin } from "src/interfaces/login";
import api_version from "src/constants/api_version.json";
import { iUpdatePassword } from "src/interfaces/updatePassword";

class LoginConsumer {
  static login(dataLogin: iLogin) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/auth/login`,
      dataLogin
    );
  }

  static validCode(data: { code: string; loginOrEmail: string }) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/auth/validateaccesscode`,
      data
    );
  }

  static refreshToken(refreshToken: string) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/auth/refreshtoken/refresh-token`,
      {
        refreshToken,
      }
    );
  }

  static checkLogin() {
    return httpClientLogin.get(`/api/v${api_version.version}/auth/check`);
  }

  static getTokenToChangePassword(email: string) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/users/userrequestrestpassword?emailOrUser=${email}`
    );
  }

  static validatedTokenToChangePassword(token: string) {
    const search = new URLSearchParams();
    search.append("token", token);

    return httpClientLogin.post(
      `/api/v${api_version.version}/users/vaidatetoken?${search.toString()}`
    );
  }

  static updatePassword(body: iUpdatePassword) {
    return httpClientLogin.post(
      `/api/v${api_version.version}/users/updateuserpassword`,
      body
    );
  }
}

export default LoginConsumer;
