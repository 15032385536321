import React, { useRef } from "react";
import { Card, CardContent, Typography, Grid, TextField } from "@mui/material";
import useLogin from "src/hooks/useLogin";
import LOGO from "src/assets/images/Logo.svg";
import { useParams } from "react-router-dom";
import { tLanguage } from "src/types/language";
import translations from "src/_i18n/translations.json";

const ValidationCodeInput = ({
  usernameDisplay,
}: {
  usernameDisplay: string;
}) => {
  const { lang } = useParams();
  const param = lang as tLanguage["en"] | tLanguage["pt"] | tLanguage["es"];
  const { code, handleChange } = useLogin();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleInputChange = (index: number, value: string) => {
    handleChange(index, value);
    if (value.length === 1 && index < code.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <Card
      variant="outlined"
      style={{ backgroundColor: "#ffffff", padding: "5%" }}
    >
      <CardContent>
        <Grid
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="subtitle1" gutterBottom textAlign="center">
            {translations[param]["message_login_one"]}
          </Typography>
          <Typography variant="subtitle1" gutterBottom textAlign="center">
            {translations[param]["message_login_two"]}
            <Typography
              variant="h6"
              gutterBottom
              color="primary"
              textAlign="center"
            >
              {usernameDisplay}
            </Typography>
            {translations[param]["message_login_three"]}
          </Typography>
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            color="primary"
            textAlign="center"
          >
            {translations[param]["enter_the_validation_code"]}
          </Typography>
        </Grid>
        <Grid container spacing={1} justifyContent="center">
          {code.map((digit, index) => (
            <Grid item key={index}>
              <TextField
                variant="outlined"
                type="text"
                value={digit}
                onChange={(e) => handleInputChange(index, e.target.value)}
                inputRef={(el) => (inputRefs.current[index] = el)}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: "center",
                    width: "2rem",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "10px", marginTop: "10%" }}
        >
          <img alt="logo" src={LOGO} />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ValidationCodeInput;
